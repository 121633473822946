<template>
    <div style="width: 100% !important;">

        <!--Email | Password-->
        <v-row class="mt-0" no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

                <v-form ref="loginForm" v-on:submit.prevent="login()" class="mt-4" v-model="valid" lazy-validation>

                    <!--Email-->
                    <app-input input-type="textInput"
                               append-icon="icons8-envelope"
                               :error="errors.userEmail"
                               :error-messages="errors.userEmail ? errors.userEmailErrorMessage : ''"
                               label="Email"
                               v-model.trim="userEmail"/>

                    <!--Password-->
                    <v-text-field @click:append="show = !show"
                                  :append-icon="show ? 'icons8-eye' : 'icons8-invisible'"
                                  :background-color="inputBackgroundColor"
                                  class="rounded-lg mt-4"
                                  :error="errors.userPassword"
                                  :error-messages="errors.userPassword ? errors.userPasswordErrorMessage : ''"
                                  filled
                                  flat
                                  hide-details="auto"
                                  :label="$t('fields.password')"
                                  outlined
                                  required
                                  :type="show ? 'text' : 'password'"
                                  v-model.trim="userPassword"
                                  v-on:keyup.enter="validateForm()"/>

                </v-form>

            </v-col>
        </v-row>

        <!--Action buttons-->
        <v-row class="my-4" no-gutters>

            <!--Register-->
            <v-col cols="6" class="pr-2">
                <app-btn @click.native="MIX_go('/register')"
                         block
                         color="grey"
                         label="Register"/>
            </v-col>

            <v-spacer/>

            <!--Next-->
            <v-col cols="6" class="pl-2">
                <app-btn @click.native="validateForm"
                         block
                         color="primary"
                         label="Next"/>
            </v-col>

        </v-row>

    </div>
</template>

<script>
export default {
    name: 'Login',

    data: () => ({
        inputBackgroundColor: 'white',
        valid: true,
        show: false,
        userEmail: '',
        userPassword: '',
        loading: false, // to show loading bar
        errors: {
            userEmail: false,
            userEmailErrorMessage: '',
            userPassword: false,
            userPasswordErrorMessage: '',
        },
    }),

    methods: {

        checkCurrentUser(userID) {
            const t = this
            let currentUser = [];

            t.$firebase.db.collection('users').doc(userID).get()
                .then((doc) => {

                    if (doc.exists) {
                        const document = doc.data()
                        document.id = doc.id
                        currentUser = document
                    } else {
                        currentUser = null
                        t.MIX_alert(-1, 'Account not found.', null, null)
                    }
                })

            return currentUser
        },

        /**
         * Validate Form
         *
         * Validate the form before logging in.
         * If there are any errors, an error message will appear under the input.
         * If there are no errors, login() will be called
         */
        validateForm() {
            const t = this

            // Reset errors
            t.errors.userEmail = false
            t.errors.userPassword = false

            // If no email is present
            if (!t.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is required'
            }
            // If email is not valid
            else if (!/.+@.+\..+/.test(t.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is not valid'
            }

            // If no password is present
            if (!t.userPassword.trim()) {
                t.errors.userPassword = true
                t.errors.userPasswordErrorMessage = 'Password is required'
            }

            // If there are no errors, call to log in
            if (!Object.values(t.errors).includes(true)) {
                t.login()
            }
        },

        login() {
            let t = this

            t.MIX_progressBar({show: true})

            t.userEmail = t.userEmail.toLowerCase()

            if (t.$refs.loginForm.validate()) {
                t.$firebase.auth
                    .signInWithEmailAndPassword(t.userEmail.trim(), t.userPassword.trim())
                    .then(data => {
                        t.loading = true

                        const user = t.checkCurrentUser(data.user.uid)

                        if (user !== null) {
                            t.MIX_progressBar({show: false})
                            t.MIX_FIREBASE_currentUser(data.user)

                            // TODO REMOVE this line
                            this.$store.commit("SET_currentUser", data.user)

                            setTimeout(() => {
                                t.$router.push("/")
                                    .catch(error => {
                                        console.log('Login error: ', error.message)
                                    })
                            }, 1500)
                        }

                    })
                    .catch(err => {
                        console.log('Login error: ', err)
                        t.MIX_alert(-1, err, null, null)
                    })
            } else {
                t.MIX_alert(-1, 'Either the email or password is incorrect.', null, null)
            }
        }

    }
}
</script>