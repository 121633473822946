<!--
Delete Icon Dialog

Pop-up dialog to either cancel or confirm deleting a document.
Clicking the 'Delete' button will emit a message back to the calling component, which will handle the deletion.
Clicking the 'Cancel' button will simple close the dialog.
-->
<template>
    <v-dialog max-width="512"
              transition="dialog-bottom-transition"
              v-model="dialog">

        <!--Activator-->
        <template v-slot:activator="{on, attrs}">
            <v-icon class="icons8-trash deleteButton"
                    :disabled="disabled"
                    size="32"
                    v-on="on"/>
        </template>

        <!--Body-->
        <v-card>

            <!--Toolbar-->
            <v-toolbar class="px-2" color="primary">

                <!--Title-->
                <app-text category="text-medium" class="white--text">Delete</app-text>

                <v-spacer/>

                <!--Close Icon-->
                <v-icon @click.native="closeDialog"
                        class="icons8-delete" color="white" size="32"/>

            </v-toolbar>

            <!--Content-->
            <div class="pa-4">

                <!--Message-->
                <app-text>You are about to delete <span class="font-weight-bold">{{ itemToDelete }}</span></app-text>

                <!--Message-->
                <app-text category="text-default-bold" class="mt-4">Are you sure you want to do this?</app-text>

                <!--Action Buttons-->
                <div class="d-flex justify-space-between mt-4">

                    <!--Cancel-->
                    <app-btn @click.native="closeDialog"
                             color="lightgrey" icon="icons8-cancel" label="Cancel"/>

                    <!--Delete-->
                    <app-btn @click.native="handleDeleteButton"
                             color="error" icon="icons8-trash" label="Delete"/>

                </div>

            </div>

        </v-card>

    </v-dialog>
</template>

<script>
export default {

    name: "deleteIconDialog",

    props: ['disabled', 'itemToDelete'],

    data: () => ({
        dialog: false,
    }),

    methods: {

        /**
         * Close Dialog
         *
         * Close the current dialog.
         */
        closeDialog() {
            const t = this

            t.dialog = false
        },

        /**
         * Handle Delete Button
         *
         * Emit a message to confirm deletion back to the calling component, so it can be handled.
         */
        handleDeleteButton() {
            const t = this

            t.$emit('emitDeleteFromDialog')

            t.dialog = false
        },

    }

}
</script>

<style scoped>
.deleteButton {
    border-radius: 50%;
    color: red;
    padding: 8px;
    transition: 0.5s;
}

.deleteButton:hover {
    background: red;
    color: white;
}
</style>
