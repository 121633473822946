<template>
<div style="width: 100%">
		<v-row
			class="d-flex justify-center align-center"
			no-gutters
			fill-height
		>
			<v-col cols="12" class="d-flex align-center pa-0 ma-0">
				<mapbox
					:width="'100vw'"
					:height="'80vh'"
					class="maplayer"
					v-on:map-click="mapClick"
					:marker="marker"
					style="
						top: 0px !important;
						left: 0px !important;
					"
				></mapbox>
			</v-col>
		</v-row>
	<v-btn v-if="!fullMap && $vuetify.breakpoint.width > 600" @click="fullScreen()"
		color="primary" style="
			font-weight: bold;
			position: absolute;
			top: 5vh;
			right: 2vw;"
		>FULL SCREEN</v-btn>
	</div>
</template> 

<script>
import { mapGetters } from 'vuex';
import mapbox from "../../components/mapbox"

export default {
    name: 'Map',
    components: {
        mapbox,
    },
	// middleware: 'authenticated',
	data: () => ({
		device: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [],
            },
            properties: {
                name: '',
                id: '',
                value: '',
                color: '#582768',
                icon: 'school-white',
                alert: 'false',
                textColor: '#582768',
                circle: 'true',
            },
		},
		isFullScreen: false,
		fullMap: false,
		isFormValid: false,
		loading: true,
		marker: null,
	}),
	computed: {
		// ...mapGetters({
		// 	GET_authenticated: 'GET_authenticated',
		// 	GET_firebaseUser: 'GET_firebaseUser',
		// }),

		isMapPage() {
			if (this.route.name = 'mapPage') {
				this.fullMap = true
			}
		}
	},
	methods: {
		mapClick(value) {
			// console.log("emitted click value")
			// alert(JSON.stringify(value));
		},

		fullScreen() {
			const t = this
			t.isFullScreen = !t.isFullScreen
			t.fullMap = true
			t.MIX_go('mapPage')

		},
	},
};
</script>

<style>
.mapboxgl-ctrl-top-right {
    top:10vh;
	right:4vw
}

/* Hide Mapbox branding */
.mapboxgl-ctrl-bottom-right {
	display:none
}

.mapboxgl-ctrl-bottom-left {
    display:none
}
</style>