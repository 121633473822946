<!--
Sites

Renders the Sites page which displays a table containing the Sites data.

The page also contains an Site form where the authorised User can add/edit/delete Sites.
-->
<template>
    <div class="ma-0 px-4">

    <!-- <v-divider class="ma-4"/> -->

        <v-row class="my-4" no-gutters>

            <!--Search-->
            <v-col v-if="component === 'sitesTable'" :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6"
                class="pa-0 mb-4">

                <app-input input-type="textInput"
                           clearable
                           :label="$t('filters.search')"
                           :append-icon="'icons8-search'"
                           v-model="search"/>

            </v-col>

            <!--New Site Button-->
            <v-col v-if="component === 'sitesTable'"  class="d-flex justify-end align-end pa-0 mb-4">

                <app-btn @click.native="component = 'siteForm'; newFormButtonClicked = true"
                        :block="$vuetify.breakpoint.width <= 600"
                        color="primary"
                        icon="icons8-skyscrapers"
                        label="New Site"/>

            </v-col>

            <!-- Switch component between siteTable and siteForm-->
            <component :is="component" v-on:switchComponent="switchComponent" v-bind="currentProperties"></component>

        </v-row>

    </div>
</template>

<script>

import SiteForm from '../../components/siteForm.vue'
import SitesTable from '../../components/sitesTable.vue'

export default {

    components: {SiteForm, SitesTable},

    name: "Sites",

    props: ['organisationId', 'organisationName'],

    data: () => ({
        title: 'Sites',
        collection: 'sites',
        collectionItem: 'site',
        collectionTitle: 'Sites',
        collectionItemTitle: 'Site',
        component: 'sitesTable', 
        formMode: '',
        newFormButtonClicked: false,
        siteData:[],
        search: '',
        tableData: [],
    }),

    computed: {

        currentProperties() {
            const t = this

            if (t.component === 'sitesTable') {
                return { tableData: t.tableData, organisationId: t.organisationId, search:t.search }
            }
            if (t.component === 'siteForm' && t.newFormButtonClicked) {
                // Reset the value to false
                t.newFormButtonClicked = false
                return { siteData: t.siteData, formMode: 'New', organisationId: t.organisationId, organisationName: t.organisationName }
            } else if (t.component === 'siteForm') {
                return { siteData: t.siteData, formMode: 'View', organisationId: t.organisationId, organisationName: t.organisationName }
            }

        },

    },

    methods: {
        /**
         * Switch component to show either sites table or site form 
         *
         */

        switchComponent(switchFrom, siteData) {
            const t = this
            
            if(switchFrom === 'siteForm') {
                t.component = 'sitesTable'
            } else if(switchFrom === 'sitesTable') {
                t.component = 'siteForm'
                t.siteData = siteData
            }
        },

        /**
         * Get Sites Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Sites collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this
            const collection = t.$firebase.db.collection('sites')

            collection.onSnapshot(snapShot => {

                // Clear the table data to avoid duplications
                t.tableData = []

                snapShot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.tableData.push(document)
                    }
                })
            })
        }
    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getSitesCollectionData()
    }

}
</script>
