<template>
    <div style="width: 100%">

        <!--Table-->
        <v-row v-if="$vuetify.breakpoint.width >= 600" class="ma-0" no-gutters>
            <!-- <v-col class="pa-0"> -->

                <v-data-table class="rounded-lg"
                            :headers="computedHeaders"
                            :items="filteredTableData"
                            :search="search"
                            style="width: 100%"
                            sort-by="deviceName">

                    <!--Device Name-->
                    <template v-slot:item.deviceName="{ item }">
                        <app-text category="text-small">{{ item.deviceName }}</app-text>
                    </template>

                    <!--Device Id-->
                    <template v-slot:item.deviceId="{ item }">
                        <app-text category="text-small">{{ item.deviceId }}</app-text>
                    </template>

                    <!--Device Type-->
                    <template v-slot:item.deviceType="{ item }">
                        <app-text category="text-small">{{ item.deviceType }}</app-text>
                    </template>

                    <!--Device Location-->
                    <template v-slot:item.deviceLocation="{ item }">
                        <app-text category="text-small">{{ item.deviceLocation }}</app-text>
                    </template>

                    <!--Device's Site Id-->
                    <template v-slot:item.siteName="{ item }">
                        <app-text category="text-small">{{ item.siteName }}</app-text>
                    </template>

                    <!--Action-->
                    <template v-slot:item.actions="{ item }">
                        <div class="d-flex align-center justify-end" style="height: 100%">
                            <v-btn @click.native="openDevice(item.id)"
                                depressed class="white text--red">Open
                                <v-icon color="primary" class="icons8-forward"/>
                            </v-btn>
                        </div>
                    </template>

                </v-data-table>

            <!-- </v-col> -->
        </v-row>

        <!--Mobile Cards-->
        <v-row class="ma-0" no-gutters>
            <v-col v-if="$vuetify.breakpoint.width < 600">
                <div v-for="device in filteredTableData" :key="device.id">

                    <v-card @click.native="openDevice(device.id)"
                            class="rounded-lg mb-4 pa-4"
                            flat>

                        <!--Device Name-->
                        <app-text category="text-default-bold" class="mb-2">
                            {{ device.deviceName }}
                        </app-text>

                        <!--Other Details-->
                        <div class="d-flex">

                            <!--Device Id-->
                            <div class="d-flex align-center">
                                <v-icon class="icons8-person mr-2" color="primary" size="16"/>
                                <app-text category="text-default" class="grey--text mr-4">
                                    {{ device.deviceId }}
                                </app-text>
                            </div>

                            <!--Device Type-->
                            <!-- <div class="d-flex align-center">
                                <v-icon class="icons8-phone mr-2" color="primary" size="16"/>
                                <app-text category="text-default" class="grey--text">
                                    {{ device.deviceType }}
                                </app-text>
                            </div> -->

                            <!--Device Location-->
                            <div class="d-flex align-center">
                                <v-icon class="icons8-skyscrapers mr-2" color="primary" size="16"/>
                                <app-text category="text-default" class="grey--text">
                                    {{ device.deviceLocation }}
                                </app-text>
                            </div>

                        </div>

                    </v-card>

                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'devicesTable',

    props: ['tableData', 'organisationId', 'search'],

    data: () => ({

        // search: '',

        headers: [
            {text: 'Device Name', value: 'deviceName', align: 'left', sortable: false},
            {text: 'Device Id', value: 'deviceId', align: 'left', sortable: false, hidden: false},
            {text: 'Device Type', value: 'deviceType', align: 'left', sortable: false},
            {text: 'Device Location', value: 'deviceLocation', align: 'left', sortable: false},
            {text: 'Site Name', value: 'siteName', align: 'left', sortable: false},
            {text: '', value: 'actions', align: 'right', sortable: false, width: '134px'},
        ],
    }),
    computed: {

        /**
         * Computed Headers
         *
         * Returns a filtered headers array which is only those not marked as hidden.
         */
        computedHeaders() {
            const t = this

            return t.headers.filter(header => !header.hidden)
        },

        /**
         * Filtered Table Data
         *
         * Take the tableData and return an array of filtered Devices.
         *
         * @returns array - filtered users
         */
        filteredTableData() {
            const t = this
            let tableData = t.tableData

            if (t.organisationId) {
                tableData = tableData.filter(device =>  {
                    return device.organisationId == t.organisationId
                    })
            } else {
                tableData = []
            }

            return tableData
        },

    },

    methods: {
    /**
     * Open Device
     *
     * Filter through the devices and return the details of the device opened
     *
     * Emit 'switchComponent' to Devices component to render deviceForm component
     */

        openDevice(deviceId) {
           const t = this
           let deviceData

            if (deviceId) {
                deviceData = t.tableData.filter(device =>  {
                    return (device.id == deviceId)
                })
            }

            t.$emit('switchComponent', 'devicesTable', deviceData[0])

        }

    },

}

</script>

<style scoped>
    
</style>