<template>
<div style="width: 100%">
	<div id="map" :style="style"></div>
	<!-- Show the Map filters -->
	<v-row v-if="isMapPage"
		style="position: absolute;
			   top: 12px;
			   background-color: white;
			   width:100vw"
		>
	</v-row>
	<!-- Show home page version of Map filters -->
	<v-row v-else-if="!isMapPage && $vuetify.breakpoint.width > 600" class="d-flex flex-column mr-12"
		style="position: absolute;
			   top: 8vh;
			   right: 2vw;
			   background-color: white;
			   width: 220px;
			   justify-content: start;"
		>
	</v-row>
</div>

</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'Mapbox',
	props: {
		width: {
			type: String,
			default: '100vw',
			required: false,
		},
		height: {
			type: String,
			default: '100vh',
			required: false,
		},
		marker: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	data: () => ({
		devices: {
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [],
					},
					properties: {
						name: '',
						id: '',
						value: '',
						color: '',
						icon: '',
						alert: 'false',
						textColor: '',
						circle: '',
					},
				},
			],
		},
		features: [],
		map: {},
		mapLoading: false,
		mapData: {
			center: [-4.2283417, 51.7089602],
			style: 'mapbox://styles/mapbox/light-v9',
			zoom: 7,
		},
		symbols: [
			{
				name: 'business-white',
				url: 'https://img.icons8.com/material/25/FFFFFF/building--v1.png',
			},
		],
	}),
	computed: {
		isMapPage() {
			return this.$route.name == 'MapPage'
		},

		style() {
			return `width: ${this.width} !important; height: ${this.height} !important;`;
		},
	},
	watch: {
		marker: {
			handler() {
				this.addLayer(this.marker);
			},
			deep: true,
		},
	},
	methods: {



		...mapActions({
			// ACT_setData: 'ACT_setData',
		}),
		async createMap() {
			return new Promise((resolve, reject) => {
				const t = this;
				t.mapLoading = true;
				t.map = new t.$mapboxgl.Map({
					accessToken: 'pk.eyJ1IjoidmluZGljbyIsImEiOiJjanY2a3VpanQwMHZhM3lwZm1oaWxhemtnIn0.D8js3Hp9ss34dscb5Z1Y9Q', // process.env.MAPBOX_TOKEN,
					container: 'map', // <div id="map"></div>
					style: t.mapData.style, //'mapbox://styles/mapbox/streets-v9', // default style
					center: t.mapData.center, //[-21.9270884, 64.1436456], // starting position as [lng, lat]
					zoom: t.mapData.zoom, //13
				});
				const nav = new t.$mapboxgl.NavigationControl();
				t.map.addControl(nav, 'top-right');
				t.map.on('load', () => {
					t.symbols.forEach((e) => {
						t.map.loadImage(e.url, function (error, res) {
							t.map.addImage(e.name, res);
						});
					});
					t.mapLoading = false;
					resolve({
						code: 1,
						message: 'Map has Loaded Successfully',
						data: null,
						error: null,
					});
				});
			});
		},

		addLayer(payload) {
			var t = this;
			return new Promise((resolve, reject) => {
				// Add geoJSON Source by URL
				t.map.addSource(payload.sourceName, {
					type: 'geojson',
					data: payload.sourceURL, // Use a URL for the value for the `data` property.
				});
				// Add Alert Layer to Map
				t.map.addLayer({
					id: payload.layerId + '-alert',
					source: payload.sourceName,
					type: 'circle',
					paint: {
						'circle-radius': payload.initialRadius,
						'circle-color': ['get', 'color'],
					},
					filter: ['==', 'alert', 'true'], // Filter where geoJSON data property alert: true
				});
				// Add Circle Layer to Map
				t.map.addLayer({
					id: payload.layerId + 'circle',
					source: payload.sourceName,
					type: 'circle',
					paint: {
						'circle-radius': payload.initialRadius,
						'circle-radius-transition': { duration: 0 },
						'circle-opacity-transition': { duration: 0 },
						'circle-color': ['string', ['get', 'color']],
					},
					filter: ['==', 'circle', 'true'], // Filter where geoJSON data property alert: true
				});
				// Switch between Symbol or Text on Circle
				switch (payload.layerType) {
					case 'symbol':
						// Use Symbol
						t.map.addLayer({
							id: payload.layerId,
							source: payload.sourceName,
							type: 'symbol',
							layout: {
								'icon-image': '{icon}',
								'icon-allow-overlap': false,
							},
						});
						break;
					case 'value':
						// Use Text
						t.map.addLayer({
							id: payload.layerId + '-value',
							type: 'symbol',
							source: payload.sourceName,
							layout: {
								'text-field': ['get', 'value'],
								'text-size': 12,
								'text-font': [
									'Open Sans Semibold',
									'Arial Unicode MS Bold',
								],
							},
							paint: {
								'text-color': '#ffffff',
							},
						});
				}
				// Show Name Label or Not
				if (payload.showLabel) {
					t.map.addLayer({
						id: payload.layerId + '-label',
						source: payload.sourceName,
						type: 'symbol',
						layout: {
							'text-field': ['get', 'name'],
							'text-size': 14,
							'text-font': [
								'Open Sans Semibold',
								'Arial Unicode MS Bold',
							],
							'text-offset': [0, 2.5],
							'text-anchor': 'center',
						},
						paint: {
							'text-color': ['string', ['get', 'textColor']],
						},
					});
				}
				var framesPerSecond = 10;
				var initialOpacity = 1;
				var opacity = initialOpacity;
				var initialRadius = 20;
				var radius = initialRadius;
				var maxRadius = 60;
				// Alert Animation (Pulsing)
				function animateMarker(timestamp) {
					setTimeout(function () {
						requestAnimationFrame(animateMarker);
						radius += (maxRadius - radius) / framesPerSecond;
						opacity -= 0.9 / framesPerSecond;
						if (opacity <= 0) {
							radius = initialRadius;
							opacity = initialOpacity;
						}
						t.map.setPaintProperty(
							payload.layerId + '-alert',
							'circle-radius',
							radius
						);
						t.map.setPaintProperty(
							payload.layerId + '-alert',
							'circle-opacity',
							opacity
						);
					}, 1000 / framesPerSecond);
				}
				// Start the animation.
				animateMarker(0);
				// On Layer Click
				t.map.on('click', payload.layerId, function (e) {
					var features = t.map.queryRenderedFeatures(e.point, {
						layers: [payload.layerId],
					});
					// console.log(
					// 	'features = ' +
					// 		JSON.stringify(features[0].properties, null, 2)
					// );
					// t.$emit('map-click', { data: features[0].properties });
				});
				// ON Mouse Enter
				t.map.on('mouseenter', payload.layerId, () => {
					t.map.getCanvas().style.cursor = 'pointer';
				});
				// On Mouse Leave
				t.map.on('mouseleave', payload.layerId, () => {
					t.map.getCanvas().style.cursor = '';
				});
				resolve({
					code: 1,
					message: 'Layer Added Successfully',
					data: null,
					error: null,
				});
			});
		},
		//* Gets all collection from firebase and insert to map
		async getAllNodes() {
			let t = this;
			await t.$firebase.db
				.collection('devices')
				.get()
				.then((querySnapshot) => {
					querySnapshot.docs.forEach((doc) => {
						const document = doc.data();
						const device = {
							type: 'Feature',
							geometry: {
								type: 'Point',
								coordinates: [
									document.deviceLongitude,
									document.deviceLatitude,
								],
							},
							properties: {
								name: document.deviceName,
								id: document.id,
								status: document.deviceStatus,
								color: '#582768',
								icon: 'business-white',
								alert: 'false',
								textColor: '#242526',
								circle: 'true',
							},
						};


						t.devices.features.push(device)
					});
				})
				.catch((err) => {
					console.log(err);
				});
		},
	},
	async mounted() {
		let t = this;
		t.getAllNodes();
		let createMapResult = await t.createMap();
		if (createMapResult.code === 1) {
			t.devices.features.forEach(async (device) => {
				let payload = {};
				payload = {};
				payload.layerId = 'task:' + device.properties.id;
				payload.sourceName = 'task-layer:' + device.properties.id;
				payload.sourceURL = device;
				payload.layerType = 'symbol';
				payload.initialRadius = 20;
				payload.showLabel = true;
				await t.addLayer(payload);
			});
		}
	},
};
</script>
