<!--
Members

Renders the Members page which displays a table containing the Members data.

The page also contains a Member form where the authorised User can add/edit/delete Members.
-->
<template>
    <div class="ma-0 px-4">

    <!-- <v-divider class="ma-4"/> -->

        <v-row class="mt-4" no-gutters>

            <!--Search-->
            <v-col :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6"
                   class="pa-0 mb-4">

                <app-input input-type="textInput"
                        clearable
                        :label="$t('filters.search')"
                        :append-icon="'icons8-search'"
                        v-model="search"/>

            </v-col>

            <!--Invite Member Button-->
            <v-col class="justify-end align-end d-flex pa-0 mb-4">

                <!-- Show only when an organisation has been seleted -->
                <organisations-invite-dialog v-if="organisationId" :usersCollectionData="tableData" :organisationName="organisationName" :organisationId="organisationId"/>

            </v-col>

        </v-row>

        <!--Table-->
        <v-row v-if="$vuetify.breakpoint.width >= 600" class="ma-0" no-gutters>

            <v-data-table class="rounded-lg"
                    :headers="computedHeaders"
                    :items="filteredTableData"
                    :search="search"
                    style="width: 100%"
                    sort-by="userName">

                <!-- Member Name-->
                <template v-slot:item.userName="{ item }">
                    <app-text category="text-small">{{ item.userName }}</app-text>
                </template>

                <!-- Member Telephone -->
                <template v-slot:item.userTelephone="{ item }">
                    <app-text category="text-small">{{ item.userTelephone }}</app-text>
                </template>

                <!--Member Email-->
                <template v-slot:item.userEmail="{ item }">
                    <app-text category="text-small">{{ item.userEmail }}</app-text>
                </template>

                <!--Action-->
                <!-- <template v-slot:item.actions="{ item }">
                    <div class="d-flex align-center justify-end" style="height: 100%">
                        <v-btn @click="openItem(item.id, 'View', false)"
                            depressed class="white text--red">Open
                            <v-icon color="primary" class="icons8-forward"/>
                        </v-btn>
                    </div>
                </template> -->

            </v-data-table>

        </v-row>

        <!--Mobile Cards-->
        <v-row class="ma-0" no-gutters>
            <v-col v-if="$vuetify.breakpoint.width < 600">
                <div v-for="member in filteredTableData" :key="member.id">

                    <v-card class="rounded-lg mb-4 pa-4"
                            flat>

                    <!-- @click="openItem(member.id, 'View', false)" -->

                        <!--Name-->
                        <app-text category="text-default-bold" class="mb-2">
                            {{ member.userName }}
                        </app-text>

                        <!--Contact details-->
                        <div class="d-flex">

                            <!--Contact telephone-->
                            <!-- <div class="d-flex align-center">
                                <v-icon class="icons8-phone mr-2" color="primary" size="16"/>
                                <app-text category="text-default" class="grey--text mr-4">
                                    {{ member.userTelephone }}
                                </app-text>
                            </div> -->

                            <!--Contact email-->
                            <div class="d-flex align-center">
                                <v-icon class="icons8-email mr-2" color="primary" size="16"/>
                                <app-text category="text-default" class="grey--text">
                                    {{ member.userEmail }}
                                </app-text>
                            </div>

                        </div>

                    </v-card>

                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import OrganisationsInviteDialog from '../organisations/organisationsInviteDialog/organisationsInviteDialog.vue';

export default {
    name: "Members",

    components: {OrganisationsInviteDialog},

    props: ['organisationId', 'organisationName'],

    data: () => ({
        title: 'Members',
        collection: 'users',
        collectionItem: 'user',
        collectionTitle: 'Users',
        collectionItemTitle: 'user',
        headers: [
            {text: 'Name', value: 'userName', align: 'left', sortable: false},
            {text: 'Telephone', value: 'userTelephone', align: 'left', sortable: false},
            {text: 'Email', value: 'userEmail', align: 'left', sortable: false},
            {text: 'Role', value: 'userRole', align: 'left', sortable: false},
            // {text: '', value: 'actions', align: 'right', sortable: false, width: '134px'},
        ],
        search: '',
        tableData: [],
    }),

    computed: {
        /**
         * Computed Headers
         *
         * Returns a filtered headers array which is only those not marked as hidden.
         */
        computedHeaders() {
            const t = this

            return t.headers.filter(header => !header.hidden)
        },


        /**
         * Filtered Table Data
         *
         * Take the tableData and return an array of filtered Members that are a part of the Organisation.
         *
         * @returns array - filtered users
         */
        filteredTableData() {
            const t = this
            let tableData = t.tableData

            // Get members of the Organisation
            if(t.organisationId) {
                tableData = tableData.filter(member =>  {
                return (member.organisationId == t.organisationId || member.organisationId?.includes(t.organisationId))
                })
            } else {
                tableData = []
            }

            return tableData
        },

    },

    methods: {
        /**
         * Get Users Collection (Members)
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('users')
            collection.onSnapshot(snapShot => {

                // Clear the table data to avoid duplications
                t.tableData = []

                snapShot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.tableData.push(document)
                    }
                })
            })
        }
    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getUsersCollectionData()
    }

}
</script>
