<!--
Organisation

Renders the form to add/edit/delete an Organisation. Renders the Members, Sites and Devices components.
-->
<template>

    <!--Form-->
    <v-form>

        {{ computedFormData }}

        <v-row class="px-4" no-gutters>

            <!--Contact-->
            <v-col class="mt-4 mb-2 ml1" cols="12">
                <!-- <app-text category="text-medium" class="grey--text">Contact Information</app-text> -->
            </v-col>

            <!--Logo picture-->
            <v-col
                class="d-flex justify-center align-center pa-2 rounded-lg white profileImage-container "
                :class="$vuetify.breakpoint.width >= 600 ? 'flex-grow-0 mr-4' : ''"
                :cols="$vuetify.breakpoint.width < 600 && 12"
                style="height: 288px; width: 288px">

                <!--If an image is present, render it-->
                <div v-if="form.organisationLogo && !tempImage"
                     class="d-flex flex-column align-center">

                    <!--Image-->
                    <v-img :src="form.organisationLogo"
                           class="rounded-lg" cover height="254" width="254"/>

                    <!--Upload button-->
                    <div v-if="formMode === 'Edit'"
                         style="position: absolute; z-index: 9; margin-top: 200px">

                        <photoupload class="mr-n4"
                                     allowedtypes="image/*"
                                     :docLink="{collection: 'users', documentId: form.id}"
                                     folder="users-profile-pictures"/>

                    </div>

                </div>

                <!--If a tempImage (upload preview) is present, render it-->
                <div v-else-if="tempImage"
                     class="d-flex flex-column align-center">

                    <!--Image-->
                    <v-img :src="tempImage"
                           class="rounded" cover height="216" width="216"/>

                    <!--Upload button-->
                    <div style="position: absolute; z-index: 9; margin-top: 152px">

                        <photoupload class="mr-n4"
                                     allowedtypes="image/*"
                                     :docLink="{collection: 'users', documentId: form.id}"
                                     folder="users-profile-pictures"/>

                    </div>

                </div>

                <!--If neither an image or tempImage is present, render an icon-->
                <div v-else class="d-flex flex-column align-center" style="width: 216px">

                    <!--Image-->
                    <v-icon class="icons8-customer" size="216"/>

                    <!--Upload button-->
                    <div v-if="formMode === 'New' || formMode === 'Edit'"
                         style="position: absolute; z-index: 9; margin-top: 152px">

                        <photoupload class="mr-n4"
                                     style="width: 100%"
                                     allowedtypes="image/*"
                                     :docLink="{collection: 'users', documentId: form.id}"
                                     folder="users-profile-pictures"/>

                    </div>

                </div>

            </v-col>
            <!--Organisation Name -->
            <v-col :class="$vuetify.breakpoint.width <600 ? 'flex-grow-1 mr-4' : 'd-flex flex-column '"
                   :cols="$vuetify.breakpoint.width < 600 && 12">
                <v-row no-gutters>
                    <v-col :cols="$vuetify.breakpoint.width  < 600 ? 12 :12"
                           :class="$vuetify.breakpoint.width < 600 ? 'mt-4 ' : 'pr-2' ">
                        <app-input input-type="textInput"
                                   style="width : 100%"
                                   :error="errors.organisationName"
                                   :error-messages="errors.organisationNameErrorMessage"
                                   label="Organisation Name"
                                   :is-form-read-only="computedIsFormReadOnly"
                                   prepend-inner-icon="icons8-business-building"
                                   v-model.trim="form.organisationName"/>

                    </v-col>
                    <!-- <v-col :cols="$vuetify.breakpoint.width  < 600 ? 12 :4"
                           :class="$vuetify.breakpoint.width < 600 ? 'mt-4 ' : 'pl-2 '">
                        Organisation Type
                        <app-input input-type="select"
                                   :error="errors.organisationType"
                                   :error-messages="errors.organisationTypeErrorMessage"
                                   :disabled="formReadOnly"
                                   :is-form-read-only="computedIsFormReadOnly"
                                   :items="organisationTypesArray"
                                   item-text="name"
                                   label="Organisation Type"
                                   v-model="form.organisationType"/>
                    </v-col> -->
                    <!--Contact Name-->

                    <app-input input-type="textInput"
                               class="mt-4"
                               style="width : 100%"
                               :error="errors.organisationContactName"
                               :error-messages="errors.organisationContactNameErrorMessage"
                               label="Contact Name"
                               :is-form-read-only="computedIsFormReadOnly"
                               prepend-inner-icon="icons8-person"
                               v-model.trim="form.organisationContactName"/>

                    <!--Telephone-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               style="width : 100%"
                               :error="errors.organisationTelephone"
                               :error-messages="errors.organisationTelephoneErrorMessage"
                               label="Contact Telephone"
                               :is-form-read-only="computedIsFormReadOnly"
                               prepend-inner-icon="icons8-phone"
                               type="tel"
                               v-model.trim="form.organisationTelephone"/>


                    <!--Email-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               style="width : 100%"
                               :error="errors.organisationEmail"
                               :error-messages="errors.organisationEmailErrorMessage"
                               label="Contact Email"
                               :is-form-read-only="computedIsFormReadOnly"
                               prepend-inner-icon="icons8-email"
                               type="email"
                               v-model.trim="form.organisationEmail"/>
                </v-row>
            </v-col>

            <!--Address-->
            <v-col class="mt-4 mb-2 ml-2" cols="12">
                <!-- <app-text category="text-medium" class="grey--text">Address</app-text> -->
            </v-col>
            <!--Address Line 1-->
            <v-col :cols="$vuetify.breakpoint.width   < 600 ? 12 : 6">
                 <v-text-field  ref="autocomplete" 
                                id="autocomplete" 
                                label="Address Line 1/ Search For Your Address" 
                                filled
                                flat
                                :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                class="rounded-lg"
                                outlined
                                :error="errors.organisationAddressLine1"
                                :error-messages="errors.organisationAddressLine1ErrorMessage"
                                background-color="white" 
                                :disabled="computedIsFormReadOnly"
                                v-model="form.organisationAddressLine1"
                                hide-details="auto"/>
            </v-col>

            <!--Address Line 2-->
            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                <app-input input-type="textInput"
                           :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                           label="Address Line 2"
                           :is-form-read-only="computedIsFormReadOnly"
                           prepend-inner-icon="icons8-company"
                           v-model.trim="form.organisationAddressLine2"/>
            </v-col>

            <!--Address Line 3-->
            <v-col :cols="$vuetify.breakpoint.width   < 600 ? 12 : 6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                           label="Address Line 3"
                           :is-form-read-only="computedIsFormReadOnly"
                           prepend-inner-icon="icons8-company"
                           v-model.trim="form.organisationAddressLine3"/>
            </v-col>

            <!--Town-->
            <v-col :cols="$vuetify.breakpoint.width  < 600 ? 12 : 6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                           label="Town"
                           :is-form-read-only="computedIsFormReadOnly"
                           prepend-inner-icon="icons8-company"
                           v-model.trim="form.organisationAddressTown"/>
            </v-col>

            <!--County-->
            <v-col :cols="$vuetify.breakpoint.width  < 600 ? 12 : 6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                           label="County"
                           :is-form-read-only="computedIsFormReadOnly"
                           prepend-inner-icon="icons8-company"
                           v-model.trim="form.organisationAddressCounty"/>
            </v-col>

            <!--Postcode-->
            <v-col :cols="$vuetify.breakpoint.width  < 600 ? 12 : 6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                           :error="errors.organisationAddressPostcode"
                           :error-messages="errors.organisationAddressPostcodeErrorMessage"
                           label="Postcode"
                           :is-form-read-only="computedIsFormReadOnly"
                           prepend-inner-icon="icons8-company"
                           v-model.trim="form.organisationAddressPostcode"/>
            </v-col>

        </v-row>

        <!--Save button-->
        <div v-if="!computedIsFormReadOnly"
             class="d-flex justify-end pa-4 appbackground">
            <app-btn @click.native="validateForm"
                     color="success"
                     icon="icons8-save"
                     label="Update"/>
        </div>
    </v-form>

</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";
import { gmaps } from 'x5-gmaps';

export default {

    name: "myOrganisationForm",

    props: ['formData', 'doesFormStartReadOnly'],

    data: () => ({
        collection: 'organisations',
        collectionTitle: 'Organisations',
        collectionItem: 'organisation',
        collectionItemTitle: 'Organisation',
        organisationsCollectionData: [],
        organisationTypesArray: [{name: "Sponsor", value: "Sponsor"}, {name: "Organisation", value: "Organisation"}],

        isFormReadOnly: false,

        errors: {
            organisationType: false,
            organisationTypeErrorMessage: '',
            organisationName: false,
            organisationNameErrorMessage: '',
            organisationContactName: false,
            organisationContactNameErrorMessage: '',
            organisationTelephone: false,
            organisationTelephoneErrorMessage: '',
            organisationEmail: false,
            organisationEmailErrorMessage: '',
            organisationAddressLine1: false,
            organisationAddressLine1ErrorMessage: '',
            organisationAddressPostcode: false,
            organisationAddressPostcodeErrorMessage: '',
        },

        form: {
            id: '',
            organisationName: '',
            organisationAddressLine1: '',
            organisationAddressLine2: '',
            organisationAddressLine3: '',
            organisationAddressTown: '',
            organisationAddressCounty: '',
            organisationAddressPostcode: '',
            organisationTelephone: '',
            organisationEmail: '',
            organisationContactName: '',
            organisationStatus: '',
            organisationType: '',
            organisationLogo: '',
        },
        formMode: '',
        formReadOnly: true,
        tabs: 'organisation',
        photoUploadResult: {},
        tempImage: '',
    }),

    computed: {
        computedIsFormReadOnly() {
            const t = this
            return t.$props.doesFormStartReadOnly
                ? t.$props.doesFormStartReadOnly
                : t.isFormReadOnly
                
        },
        computedFormData() {
            const t = this

            if (t.formData?.id) {

                // Assign Contact data
                t.form = t.formData;
                t.form.organisationName = t.formData.organisationName
                // // Assign Organisation data
                // t.organisationForm = formData
            }
        },
        ...mapGetters({
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult',
            GET_currentUser: 'GET_currentUser',
            GET_openItem: 'GET_openOrganisation',
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
        }),
        // computedUsersHeaders() {
        //     const t = this

        //     return t.usersHeader.filter(header => !header.hidden)
        // },

        // computedUsers() {
        //     const t = this

        //     return t.usersOrganisationsCollectionData.filter(user => user.organisationId.includes(t.form.id))
        // },

    },

    methods: {

        readyGoogleApi() {
            const autocomplete = document.getElementById('autocomplete');
            gmaps().then((maps) => {
                const options = {
                    // allow only UK addresses
                    componentRestrictions: {country: "gb"},
                    fields: ["address_components", "geometry"],
                    types: ["address"],
                };
                // populates the places list
                this.autocomplete = new maps.places.Autocomplete(autocomplete, options)
                // triggered when the user selects an address from the list
                this.autocomplete.addListener('place_changed', this.updateAddress)
                })
            },
            updateAddress(){
                const place = this.autocomplete.getPlace()
                const address = place.address_components;
                let array = this.formatAddress(address, place)
                // populate the address fields
                this.form.organisationAddressLine2 = array[1];
                this.form.organisationAddressTown = array[2];
                this.form.organisationAddressCounty = array[3];
                this.form.organisationAddressPostcode = array[4].replace(/\s/g, '');
                this.form.organisationAddressLine1 = array[0];
            },
        ...mapActions({
            ACT_openItem: "ACT_openOrganisation",
            ACT_openOrganisation: "ACT_openOrganisation"
        }),

        async getOrganisationsCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection("organisations")
            // .where("organisationId", "!=", null)
            collection.onSnapshot(snapshot => {

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    if (!document.hasOwnProperty('delete')) {
                        t.organisationsCollectionData.push(document)
                    }

                })

            })
        },

        /**
         * Upload Profile Image
         *
         * Update the user's document with a profile image path  (collection | user id | image path).
         *
         * @returns {Promise<void>}
         */
        async uploadLogo() {
            const t = this

            if (t.photoUploadResult !== {}) {

                // Save to the document with: collection | organisation id | image path
                const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                    'organisations', t.photoUploadResult.docLink, {organisationLogo: t.photoUploadResult.fileURL})

                // Call for a confirmation alert
                t.renderConfirmationAlert(updatePhotosResult, 'Photo successfully updated', 'Error updating photo')

            }
        },

        /**
         * Validate
         *
         * Validates the required fields.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this
            const emailRegex = /.+@.+\..+/
            const postcodeRegex = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]?[0-9][a-zA-Z]{2}$/

            // Organisation
            t.errors.organisationName = false
            t.errors.organisationNameErrorMessage = ''
            t.errors.organisationContactName = false
            t.errors.organisationContactNameErrorMessage = ''
            t.errors.organisationTelephone = false
            t.errors.organisationTelephoneErrorMessage = ''
            t.errors.organisationEmail = false
            t.errors.organisationEmailErrorMessage = ''
            t.errors.organisationAddressLine1 = false
            t.errors.organisationAddressLine1ErrorMessage = ''
            t.errors.organisationAddressPostcode = false
            t.errors.organisationAddressPostcodeErrorMessage = ''

            // Organisation Name
            if (!t.form.organisationName.trim()) {
                t.errors.organisationName = true
                t.errors.organisationNameErrorMessage = 'Organisation Name required'
            }

            // Organisation Contact Name
            if (!t.form.organisationContactName.trim()) {
                t.errors.organisationContactName = true
                t.errors.organisationContactNameErrorMessage = 'Contact Name required'
            } else if (t.form.organisationContactName.trim().length < 2) {
                t.errors.organisationContactName = true
                t.errors.organisationContactNameErrorMessage = 'Contact Name must be more than 2 characters'
            }

            // Organisation Contact Telephone
            if (!t.form.organisationTelephone.trim()) {
                t.errors.organisationTelephone = true
                t.errors.organisationTelephoneErrorMessage = 'Telephone Number required'
            }
            // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.organisationTelephone.trim()[1]) && (t.form.organisationTelephone.trim().length < 10 || t.form.organisationTelephone.trim().length > 11)) {
                t.errors.organisationTelephone = true
                t.errors.organisationTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.organisationTelephone.trim()[1]) && t.form.organisationTelephone.trim().length !== 11) {
                t.errors.organisationTelephone = true
                t.errors.organisationTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Number must start 01, 02, 03 or 07
            else if (!['0'].includes(t.form.organisationTelephone[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.form.organisationTelephone[1])) {
                t.errors.organisationTelephone = true
                t.errors.organisationTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // Organisation Contact Email
            if (!t.form.organisationEmail.trim()) {
                t.errors.organisationEmail = true
                t.errors.organisationEmailErrorMessage = 'Contact Email required'
            } else if (!emailRegex.test(t.form.organisationEmail)) {
                t.errors.organisationEmail = true
                t.errors.organisationEmailErrorMessage = 'Incorrect Email format'
            }

            // Address Line 1
            if (!t.form.organisationAddressLine1.trim()) {
                t.errors.organisationAddressLine1 = true
                t.errors.organisationAddressLine1ErrorMessage = 'Address Line 1 required'
            }

            // Address Postcode
            if (!t.form.organisationAddressPostcode.trim()) {
                t.errors.organisationAddressPostcode = true
                t.errors.organisationAddressPostcodeErrorMessage = 'Postcode required'
            } else if (!postcodeRegex.test(t.form.organisationAddressPostcode)) {
                t.errors.organisationAddressPostcode = true
                t.errors.organisationAddressPostcodeErrorMessage = 'Postcode not valid'
            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                this.saveDocument()
            }
        },

        /**
         * Save Document
         *
         * Save or update the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this
            let createDocumentResult

            // If the formMode is Edit, update the current document
            if (!t.computedIsFormReadOnly) {
                createDocumentResult = await t.MIX_updateDocument(t.collection, t.form)
            }

            // If the document saved/updated successfully, set the form back to viewable state
            if (createDocumentResult.code === 1) {
                t.formMode = 'View'
                t.tempImage = ''
                t.formReadOnly = true

                // Add uploaded profile image
                await t.uploadLogo();

                await t.refreshDocumentView();
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
            // close dialogs on save
            // t.MIX_closeView()

        },

        /**
         * Edit Document
         *
         * Toggle the form between an editable, and a viewable state.
         */
        editDocument() {
            const t = this

            if (t.formReadOnly) {
                t.formMode = 'Edit'
                t.formReadOnly = false

            } else {
                t.formMode = 'View'
                t.tempImage = '';
                t.formReadOnly = true
                t.refreshDocumentView()
            }

        },

        /**
         * Delete Document
         *
         * Call for the confirmation box to delete a document.
         * The deletion is handled in this file's watch -> GET_confirmBoxResult handler function.
         */
        deleteDocument: function (id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: t.collection,
                    id: id,
                    title: `Delete ${t.collectionItemTitle}`,
                    description: `Are you sure you want to delete this ${t.collectionItemTitle}: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'Name']}</strong></div>`,
                    width,
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        /**
         * Add new organationId to current user
         *
         * Update current user with organisationId of the organisation they just created
         *
         */
        addNewOrgIdToCurrentUser() {
            const t = this

            t.MIX_updateDocumentNestedArrayById('users', t.GET_currentUser.id, 'organisationId', t.form.id)

            // Update Current User until a refresh
            t.GET_currentUser.organisationId.push(t.form.id)

        },

        /**
         * Refresh Document View
         *
         * Load the new document and sets the payload onto ACT_openItem
         */
        async refreshDocumentView() {
            const t = this;

            const itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
            t.form = itemResult.data
            let payload = {}
            payload.process = 'View';
            payload.popup = false;
            payload.data = itemResult.data;

            await t.ACT_openItem(payload);
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} updated`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving ${t.collectionItemTitle}`, null, null)
            }
        },

        saveLogo() {
            this.form.organisationLogo = this.uploadedFileImg.fileURL;
        },

    },

    watch: {
        GET_photoUploadResult: {
            handler: async function () {
                const t = this

                t.photoUploadResult = t.GET_photoUploadResult
                t.tempImage = t.photoUploadResult.fileURL

            },
            deep: true
        },
        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === 'organisations') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const deleteDocumentResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id);

                        if (deleteDocumentResult.code === 1) {
                            t.MIX_alert(1, `Organisation Deleted`, deleteDocumentResult.data, null);
                            await t.refreshDocumentView();
                            t.MIX_closeView()
                        } else {
                            t.MIX_alert(-1, `Error Deleting Organisation`, null, null);
                        }
                    }
                }

            }, deep: true
        }
    },

    async mounted() {
        const t = this

        // Get and populate form data
        const openItem = await t.GET_openItem
        t.formMode = openItem.process;
        if (openItem.process === 'New') {
            t.formReadOnly = false
        } else {
            t.form = openItem.data
            t.formReadOnly = true
        }

        await t.getOrganisationsCollectionData();
        t.readyGoogleApi()
    }

}
</script>

