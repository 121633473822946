<!--
HOME

Renders the home screen which contains the following:
	- Named greeting
	- List of Devices with link to chart/{deviceId} page
	- (Admin only) Users Awaiting Approval

-->
<template>
    <v-row class="ma-2" no-gutters>

        <!--Welcome User-->
        <v-col class="pa-2 pb-0" :cols="$vuetify.breakpoint.width > 480 ? 5 : 12">

            <app-text category="text-large" class="primary--text">Welcome
                <span class="accent--text">{{ GET_currentUser.userName.split(" ")[0] }}</span>
            </app-text>

        </v-col>

        <!--Company logo-->
        <v-col v-if="$vuetify.breakpoint.width > 480" class="pa-2 pb-0" cols="7">
            <v-img class="ml-auto mr-1"
                   contain
                   max-width="288"
                   max-height="44"
                   :src="require('@/assets/company_logo_long.png')"/>
        </v-col>

        <!--Left side-->
        <v-col class="px-2 pa-4" :cols="splitWindowWidth">

            <!--List Devices-->
            <div class="pa-0">
                <v-card class="d-flex flex-column rounded-lg ma-0 pa-4" flat>

                    <!--Icon | Title-->
                    <div class="d-flex">
                        <!--QR code-->
                        <v-icon class="icons8-sensor ma-n1" color="accent" size="48"/>

                        <!--Title-->
                        <app-text v-if="GET_currentUser.organisationId.length >= 1" category="text-medium-bold" class="primary--text ml-4">
                            Devices in your account
                        </app-text>
                        <app-text v-if="GET_currentUser.organisationId.length < 1" category="text-medium-bold" class="primary--text ml-4">
                           There are no devices register to your account
                        </app-text>

                    </div>

                    <!-- Select Organisation if User is a member of more than 1 Organisation -->
                    <v-select v-if="GET_currentUser.organisationId.length > 1"
                              @change="handleOrganisationChange()"
                              inputType="select"
                              class="mt-4"
                              :class="$vuetify.breakpoint.width > 840 && 'pa-2'"
                              :items="computedUsersOrganisations"
                              label="Organisation"
                              v-model="organisationSelector"/>

                    <!--Devices Card-->
                    <div v-for="device in computedUsersDevices" :key="device.id">
                        <v-card class="rounded-lg" flat>

                            <v-divider class="my-4"/>

                            <v-row no-gutters>

                                <v-col>

                                    <!--Site Name-->
                                    <app-text category="text-default-bold" class="mb-2">
                                        {{ device.siteName }}
                                    </app-text>

                                    <!--Device Name-->
                                    <app-text category="text-default" class="mb-2">
                                        {{ device.deviceName }}
                                    </app-text>

                                    <!--Organisation Name-->
                                    <app-text category="text-default">
                                        {{ device.organisationName }}
                                    </app-text>

                                </v-col>

                                <app-btn @click.native="MIX_go('/charts/'); setUpChart(device)"
                                         color="primary"
                                         label="Open"/>

                            </v-row>

                        </v-card>
                    </div>

                </v-card>
            </div>

        </v-col>

        <!--Right side (Awaiting approval)-->
        <v-col v-if="GET_currentUser.userLevel === 'SA'"
               :class="$vuetify.breakpoint.width < 840 ? 'mb-4 px-2' : 'px-2 pa-4'" :cols="splitWindowWidth">
            <!--Users awaiting approval-->
            <awaiting-approval-home-page-card :usersAwaitingApproval="usersAwaitingApproval"
                                              :usersAwaitingApprovalMessage="usersAwaitingApprovalMessage"/>
        </v-col>

    </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import AwaitingApprovalHomePageCard from "../views/home/awaitingApprovalHomePageCard/awaitingApprovalHomePageCard";

export default {
    name: "Home",

    components: {
        AwaitingApprovalHomePageCard
    },

    data: () => ({
        isPhotoUploadDisabled: false,
        devicesCollectionData: [],
        deviceOrg: {},
        organisationsCollectionData: [],
        organisationSelector: '',
        organisationSelected: '',
        usersCollectionData: [],
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
        }),

        /**
         * Computed User's Device
         *
         * Only show devices for Organisations that the user is a member of
         */

        computedUsersDevices() {
            const t = this
            let devices

            // If User is a part of multiple orgs, display devices from selected option.
            if (t.GET_currentUser?.organisationId.length > 1) {
                devices = t.devicesCollectionData.filter(device => device.organisationId == t.organisationSelected)
            } else {
                devices = t.devicesCollectionData.filter(device => device.organisationId == t.GET_currentUser.organisationId[0])
            }
            return devices
        },

        /**
         * Computed User's Organisations
         *
         * Only show Organisations that the user is a member of
         */

        computedUsersOrganisations() {
            const t = this

            let orgArray = []

            for (let i = 0; i < t.devicesCollectionData.length; i++) {
                const key = t.devicesCollectionData[i].organisationName
                t.deviceOrg[key] = t.devicesCollectionData[i].organisationId
            }

            for (const property in t.deviceOrg) {
                if (t.GET_currentUser.organisationId.includes(t.deviceOrg[property])) {
                    orgArray.push(property)
                }
            }

            return orgArray
        },

        /**
         * Split Window Width
         *
         * Split the layout either 50/50 or 100 based on the screen width.
         *
         * @returns width - number of columns each side (left and right) should span
         */
        splitWindowWidth() {
            const t = this
            let width

            if (t.$vuetify.breakpoint.width <= 840) {
                width = 12
            } else {
                width = 6
            }

            return width
        },

        /**
         * Users Awaiting Approval Message
         *
         * Take the number of users awaiting approval and return a grammatically correct string to render.
         *
         * @returns message - message to render to screen
         */
        usersAwaitingApprovalMessage() {
            const t = this
            let message

            if (t.usersAwaitingApproval.length === 1) {
                message = `You have ${t.usersAwaitingApproval.length} account awaiting approval`
            } else {
                message = `You have ${t.usersAwaitingApproval.length} accounts awaiting approval`
            }

            return message
        },

        /**
         * Users Awaiting Approval
         *
         * Filter all users and return an array containing just those with a 'PENDING' status, awaiting approval.
         *
         * @returns array - array containing users with a 'PENDING' status
         */
        usersAwaitingApproval() {
            const t = this

            return t.usersCollectionData.filter(user => user.userStatus.toUpperCase() === 'PENDING')
        }
    },

    methods: {
        setUpChart(device) {
            this.$store.commit("SET_chartDevice", device)
        },
        handleOrganisationChange() {
            const t = this

            for (const property in t.deviceOrg) {
                if (property == t.organisationSelector) {
                    t.organisationSelected = t.deviceOrg[property]
                }
            }

        },

        /**
         * Check Local Storage
         *
         * Check the local storage for the presence of 'isGettingStartedMessageVisible'.
         * If a value is held, it will be to NOT render the message.
         */
        checkLocalStorage() {
            const t = this

            if (localStorage.isGettingStartedMessageVisible) {
                t.isGettingStartedMessageVisible = false
            }
        },

        /**
         * Get Users Collection Data
         *
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            let collection = t.$firebase.db.collection('users')

            // If user is not admin ('SA'), only get the current user data
            if (!['SA'].includes(t.GET_currentUser.userLevel)) {
                collection = collection.where("id", "==", t.GET_currentUser.id)
            }

            collection.get().then(user => {

                // Reset data to avoid duplications
                t.usersCollectionData = []

                user.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    if (t.GET_currentUser.id === document.id) {

                        // Set usual site so the Complete Profile card doesn't hang
                        if (document.usualSite) {
                            t.GET_currentUser.usualSite = document.usualSite
                        }

                        // Update the user
                        t.$store.commit('SET_currentUser', document)
                    }

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.usersCollectionData.push(document)
                    }

                })
            })
        },

        async getDevicesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('devices')
            collection.onSnapshot(snapShot => {

                // Clear the Devices collection data to avoid duplications
                t.devicesCollectionData = []

                snapShot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.devicesCollectionData.push(document)
                    }
                })
            })
        },

        async getOrganisationsCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('organisations')
            collection.get().then(org => {

                // Clear the Devices collection data to avoid duplications
                t.organisationssCollectionData = []

                org.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.organisationsCollectionData.push(document)
                    }
                })
            })
        }

    },

    watch: {
        /**
         * Photo Upload Result
         *
         * On upload of a profile picture, add its storage path to the current user's document.
         */
        GET_photoUploadResult: {
            handler: async function () {
                const t = this
                const photoUploadResult = this.GET_photoUploadResult

                // If there is data, update the current user's profile picture
                if (photoUploadResult !== {}) {

                    // Save to the document with: collection | user id | image path
                    const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                        'users', photoUploadResult.docLink, {profilePicFileURL: photoUploadResult.fileURL})

                    // Render confirmation box
                    if (updatePhotosResult.code === 1) {
                        t.isPhotoUploadDisabled = true
                        this.MIX_alert(1, 'Photo Added', updatePhotosResult.data, null)
                    } else {
                        this.MIX_alert(-1, 'Error Adding Photo', null, updatePhotosResult.error)
                    }
                }
            }, deep: true
        },
    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getUsersCollectionData()
        await t.getDevicesCollectionData()
        await t.getOrganisationsCollectionData()

        // Check local storage for UI preferences
        t.checkLocalStorage()
    }
}
</script>

<style>
.expansion-panel-content-container > div {
    padding: 16px;
}
</style>
