<template>
    <!-- <div class="text-center" style="width:100%"> -->
        <v-dialog @click:outside="closeInviteDialog()"
                  v-model="dialog"
                  transition="dialog-bottom-transition"
                  max-width="512">

            <!--Activator-->
            <template v-slot:activator="{ on, attrs }">

                <!--Invite button-->
                <v-col class="ussad-activatorButton-container pa-0">
                    <v-btn :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : ''"
                           class="rounded-lg"
                           color="primary"
                           depressed
                           :height="buttonSizeDefault"
                           v-on="on">
                        <v-icon class="icons8-email-send mr-2"/>
                        <app-text category="text-default">Invite</app-text>
                    </v-btn>
                </v-col>

            </template>

            <!--Dialog content-->
            <v-card>

                <!--Toolbar-->
                <v-toolbar color="primary">

                    <app-text category="text-medium" class="white--text ml-2">Invite A User</app-text>

                    <v-spacer/>

                    <v-btn @click="closeInviteDialog()" icon dark>
                        <v-icon class="icons8-multiply" color="white"/>
                    </v-btn>

                </v-toolbar>

                <v-window v-model="step">

                    <v-window-item :value="1">

                        <!--Body-->
                        <div class="ussad-body-container">

                            <!--Instructional text-->
                            <app-text category="text-default">
                            Use the email address field below to add a user to your Organisation
                            </app-text>

                            <!--Email link-->
                            <app-text category="text-default-bold" class="text-break mt-4">
                                    You will be adding users to {{ organisationName }}
                            </app-text>

                            <v-divider class="my-4"/>

                            <!--Email input-->
                            <div class="cid-emailInput">
                                <app-input input-type="textInput"
                                        :error="errors.emailAddress"
                                        :error-messages="errors.emailAddressErrorMessage"
                                        label="Email Address"
                                        type="email"
                                        placeholder="Email address"
                                        v-model="emailAddress"/>

                                <v-icon @click="validateEmail"
                                        class="icons8-add ml-4"
                                        color="success"
                                        :disabled="!emailAddress"
                                        size="48"/>
                            </div>

                            <!--Email addresses to invite-->
                            <div>

                                <app-text category="text-default" class="mt-4">Email addresses to invite:</app-text>
                                <app-text v-if="!emailAddresses.length" category="text-default" class="grey--text ma-4 mb-0">
                                    None added
                                </app-text>

                                <!--Emails-->
                                <div v-for="email in emailAddresses" class="mt-4">

                                    <div class="d-flex">
                                        <v-icon class="icons8-checkmark-yes mr-4" color="success"/>
                                        <app-text category="text-default">{{ email }}</app-text>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <v-divider class="ma-4 mt-0"/>

                        <!--Send button-->
                        <div class="d-flex pa-4 pt-0">
                            <v-spacer/>
                            <app-btn @click.native="checkIfUserExists"
                                    block
                                    color="success"
                                    :disabled="!emailAddresses.length"
                                    icon="icons8-checkmark-yes"
                                    label="Add to Organisation"/>
                        </div>

                    </v-window-item>

                    <!-- Second frame -->
                    <v-window-item :value="2">
                        <!--Body-->
                        <div class="ussad-body-container">

                            <!--Show if there are registered Users to add to the Org-->
                            <app-text v-if="Object.keys(registeredUsersEmails).length !== 0" category="text-default">
                            The following users have been <strong>successfully added</strong> to the Organisation: 
                                <strong>{{ organisationName }}</strong>
                            </app-text>

                            <!--Emails Added to Organisation-->
                            <div v-for="email in registeredUsersEmails" class="mt-4">

                                <div class="d-flex">
                                    <v-icon class="icons8-checkmark-yes mr-4" color="success"/>
                                    <app-text category="text-default">{{ email }}</app-text>
                                </div>

                            </div>

                            <!--Show if there are unregistered Users to invite via email-->
                            <app-text v-if="Object.keys(unregisteredUsersEmails).length !== 0" category="text-default" class="mt-4">
                                The following users have <strong>not been added</strong> to the Organisation as they have not registered with Think Air
                            </app-text>

                            <!--Emails Requiring Invites to Use the App-->
                            <div v-for="email in unregisteredUsersEmails" class="mt-4">

                                <div class="d-flex">
                                    <v-icon class="icons8-multiply mr-4" color="error"/>
                                    <app-text category="text-default">{{ email }}</app-text>
                                </div>

                            </div>

                            <!--Show if there are unregistered Users to invite via email-->
                            <app-text v-if="Object.keys(unregisteredUsersEmails).length !== 0" category="text-default-bold" class="text-break mt-4">
                                    Use the button below to invite the unregistered users to use Think Air
                            </app-text>

                        </div>

                        <v-divider class="ma-4 mt-0"/>

                        <!--Send invites if there are unregistered users to add to the Org-->
                        <div v-if="Object.keys(unregisteredUsersEmails).length !== 0" class="d-flex pa-4 pt-0">
                            <v-spacer/>
                            <app-btn @click.native="inviteUsersToOrg"
                                    block
                                    color="success"
                                    icon="icons8-checkmark-yes"
                                    label="Invite to Think Air"/>
                        </div>

                    </v-window-item>

                    <!-- Third frame -->
                    <v-window-item :value="3">
                        <!--Body-->
                        <div class="ussad-body-container">

                            <!--Show if there are registered Users to add to the Org-->
                            <app-text category="text-default">
                            The following Users have been invited to register for Think Air: 
                            </app-text>

                            <!--Emails Added to Organisation-->
                            <div v-for="email in unregisteredUsersEmails" class="mt-4">

                                <div class="d-flex">
                                    <v-icon class="icons8-checkmark-yes mr-4" color="success"/>
                                    <app-text category="text-default">{{ email }}</app-text>
                                </div>

                            </div>

                            <app-text category="text-default" class="mt-4">
                                They will be added to the Organisation <strong>{{ organisationName }}</strong> once they have registered via the link in the email
                            </app-text>

                        </div>

                        <v-divider class="ma-4 mt-0"/>

                    </v-window-item>

                </v-window>

            </v-card>

        </v-dialog>
    <!-- </div> -->
</template>

<script>
export default {

    props: ['organisationId', 'usersCollectionData', 'organisationName'],

    data: () => ({
        dialog: false,
        emailAddress: '',
        emailAddresses: [],
        registeredUsersEmails: [],
        unregisteredUsersEmails: [],

        errors: {
            emailAddress: false,
            emailAddressErrorMessage: '',
        },
        step: 1,
    }),

    methods: {
        /**
         * Close Invite Dialog
         *
         * Reset the frame back to step 1, reset data 
         */        
        closeInviteDialog() {
            const t = this

            t.dialog = false

            // Delay resetting frame until modal is closed
            setTimeout(() => {
                t.step = 1
                t.registeredUsersEmails = []
                t.unregisteredUsersEmails = []
                t.emailAddresses = []
                t.emailAddress = ''
            }, 600);

        },

        /**
         * Validate Email
         *
         * Validate the email entry for a basic formatted email address.
         */
        validateEmail() {
            const t = this
            const emailRegex = /.+@.+\..+/

            t.errors.emailAddress = false
            t.errors.emailAddressErrorMessage = ''

            if (!emailRegex.test(t.emailAddress)) {
                t.errors.emailAddress = true
                t.errors.emailAddressErrorMessage = 'Not a valid email address'
            }

            if (!Object.values(t.errors).includes(true)) {
                t.addEmailAddress()
            }
        },

        /**
         * Add Email Address
         *
         * Add the email address(es) to the addresses array for sending.
         */
        addEmailAddress() {
            const t = this

            t.emailAddresses.push(t.emailAddress)
            t.emailAddress = ''
        },

        /**
         * Add registered Users to the Organisation
         *
         * Add the organisationId field to the User 
         */
        async addExistingUsersToOrg(registeredUsersIds) {
            const t = this

            // If Users to add to Org are already registered, add them to the Org 
            if(registeredUsersIds) {
                // Loop through userIds and update firestore with the organisationId they are added to
                for (const userId of registeredUsersIds) {
                    t.MIX_updateDocumentNestedArrayById('users', userId, 'organisationId', t.organisationId)
                }
            }
        },

        /**
         * Check if User being added to the Org has already registered
         * 
         * Split up the registered Users and unregistered Users so they can be either added immediately or invited to register via email
         *
         */
        checkIfUserExists() {
            const t = this
            let registeredUsersData
            let registeredUsersIds

            // Filter REGISTERED User data and emails that are in the Users collection (can be added to an Org immediately)
            registeredUsersData = t.usersCollectionData.filter(user => t.emailAddresses.includes(user.userEmail))
            t.registeredUsersEmails = registeredUsersData.map(user => user.userEmail)
            registeredUsersIds = registeredUsersData.map(user => user.id)

            // If emailaddresses entered match existing Users in firestore, invoke addExistingUsersToOrg to add orgId to User
            if(registeredUsersData) {
                t.addExistingUsersToOrg(registeredUsersIds)
            }

            // Filter UNREGISTERED emails (require an email invite, call the FB function inviteUsersToOrg())
            t.unregisteredUsersEmails = t.emailAddresses.filter(email => !t.registeredUsersEmails.includes(email))

            // show the next Invite User frame
            t.step++;
        },

        /**
         * Invite Users
         *
         * Call the Firebase function to send email invites to unregistered Users that have been added to an Org.
         */
        inviteUsersToOrg() {
            const t = this

            if( process.env.NODE_ENV==='production'){
            const invite = t.$firebase.functions.httpsCallable('inviteUsersToOrg')
            invite({emailAddresses: t.unregisteredUsersEmails, organisationId: t.organisationId})

            .then(result => {
                console.log('organisationInviteDialog - result: ', result)
                t.dialog = false
            })
            .catch(error => {
                console.log('organisationInviteDialog - error: ', error)
            })
          
            // show the next Invite User frame
            t.step++;
            }
        },

    },

}
</script>

<style>
.ussad-activatorButton-container {
    display: flex;
    justify-content: flex-end;

    padding: 0 16px;
}

.ussad-body-container {
    padding: 16px;
}

.ussad-acceptButton-container {
    display: flex;
    justify-content: flex-end;

    padding: 0 16px 16px 16px;
}

.cid-emailInput {
    display: grid;
    grid-template-columns: 1fr auto;
}
</style>
