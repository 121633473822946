import Vue from "vue";
import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';
import imageCompression from 'browser-image-compression';
import { gmaps } from 'x5-gmaps';



const mixin = {

	data: () => ({
		buttonSizeDefault: 48,

		// Lookup Values for selection fields
		appSupportTypesOptionsData: [
			'General Enquiry',
			'Problem',
			'Request',
			'Suggestion',
		],
		userStatusOptionsData: [
			'PENDING',
			'APPROVED',
			'SUSPENDED',
			'ARCHIVED',
		],
		userTypesOptionsData: [
			'Client',
			'Staff'
		],

	}),

	methods: {
		...mapActions([
			'ACT_mobileNav',
			'ACT_breadcrumbs',
			'ACT_panelLayout',
			'ACT_lookupValues',
		]),

		/**
		 * Compress Image
		 *
		 * Compress and return image ready for uploading.
		 *
		 * @param image the file to compress
		 * @returns {Promise<File>} the compressed file if successful, or the original image if not
		 */
		 async MIX_compressImage(image) {

			// Set options for compression
			const options = {
			  maxSizeMB: 1,
			  maxWidthOrHeight: 400,
			  useWebWorker: true,
			}

			try {
			  return await imageCompression(image, options)
			} catch (error) {
			  console.log('compression error: ', error)
			  return image
			}

		},

		// * Set mobile menu
		MIX_mobileNav(payload) {
			this.ACT_mobileNav(payload);
		},

		// * PANEL LAYOUT
		MIX_setPanelLayout(leftPanel, rightPanel, show, title, dynamicComponent) {
			this.ACT_panelLayout({
				leftPanel: leftPanel,
				rightPanel: rightPanel,
				show: show,
				title: title,
				dynamicComponent: dynamicComponent
			});
		},

		MIX_breakpoint() {
			return (this.$vuetify.breakpoint.name);
		},

		MIX_itemsPerPage: function (breakpoint) {
			var result = 5;
			switch (breakpoint) {
				case 'xs':
					result = 1;
					break;
				case 'sm':
					result = 5;
					break;
				case 'md':
					result = 10;
					break;
				case 'lg':
					result = 10;
					break;
				case 'xl':
					result = 10;
					break;
				default:
					result = 5;
					break;
			}
			return result;
		},

		// * VIEW RELATED MIXINS
		MIX_closeView() {
			this.MIX_puDialog(false, '', '', '');
			this.MIX_fsDialog(false, ''); // Close Full Screen Dialog
			// this.ACT_panelLayout({ leftPanel: 12, rightPanel: 0, show: false, dynamicComponent: '' });
		},

		// * Capitalize JS String
		MIX_capitalizeText(text) {
			var capitalized = text.toLowerCase();
			capitalized = capitalized.charAt(0).toUpperCase() + capitalized.slice(1);
			return capitalized;
		},

		// * MIX BACK
		MIX_back() {
			this.$router.go(-1);
		},

		// * CHANGE ROUTE FUNCTION
		MIX_go(path) {
			this.$router.push(path).catch((err) => {
				console.log("Route error: " + err);
			});
		},

		// * FORMAT DATE TIME
		MIX_formatDateTime: function (date, formatIn, formatOut) {
			if ((date !== '') && (date !== null) && (date !== undefined)) {
				return moment(date, formatIn).format(formatOut);
			} else {
				return ''
			}
		},

		MIX_exportDocuments(headers, fileTitle, formatExport) {
			const items = formatExport

			if (headers && items[0] !== headers) {
				items.unshift(headers)
			}

			const jsonObject = JSON.stringify(items)

			// convert object to csv
			const array = typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject

			let str = ""

			for (let i = 0; i < array.length; i++) {
				let line = ""

				for (let index in array[i]) {
					if (line !== "") line += ","
					line += array[i][index];
				}

				str += line + "\r\n"
			}

			const csv = str
			const exportedFilename = fileTitle + ".csv" || "export.csv"
			const blob = new Blob([csv], {type: "text/csv;charset=utf-8;"})

			if (navigator.msSaveBlob) {
				navigator.msSaveBlob(blob, exportedFilename);
			} else {
				const link = document.createElement("a")

				if (link.download !== undefined) {
					const url = URL.createObjectURL(blob)
					link.setAttribute("href", url)
					link.setAttribute("download", exportedFilename)
					link.style.visibility = "hidden"
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				}
			}
		},

		// * sort by createdDateTime
		MIX_sortByCreatedDateTime(toSortObject) {
			var t = this;
			return toSortObject.sort(function (left, right) {
				return t.$moment(right.createdDateTime, "x").diff(t.$moment(left.createdDateTime, "x"))
			});
		},

		// MIXIN TO LOAD DATABASE DATA INTO LOOKUP TABLES STORE
		MIX_lookupTables: function () {
			var t = this;
			return new Promise(async function (resolve) {
				var lookupTablesResult = await t.MIX_readDocuments('lookupTables');
				if (lookupTablesResult.code === 1) {
					t.ACT_lookupTables(lookupTablesResult.data);
				} else {
					console.log('ERROR with lookupTablesResult \n' + JSON.stringify(lookupTablesResult, null, 2))
				}
				resolve();
			})
		},
		// MIXIN TO LOAD DATABASE DATA INTO LOOKUP VALUES STORE
		MIX_lookupValues: function () {
			var t = this;
			return new Promise(async function (resolve) {
				// GET LOOKUP VALUES
				var lookupValuesResult = await t.MIX_readDocuments('lookupValues');
				if (lookupValuesResult.code === 1) {
					t.ACT_lookupValues(lookupValuesResult.data);
				} else {
					console.log('ERROR with lookupValuesResult \n' + JSON.stringify(lookupValuesResult, null, 2))
				}
				resolve();
			})
		},

		// * Page Details
		MIX_breadcrumbs(breadcrumbs) {
			this.ACT_breadcrumbs(breadcrumbs);
		},

		// TO DELETE COMMENTS/LOCATIONS/CONCERNS FROM SUBARRAYS
		MIX_deleteCollectionArray(id, item, collection, parentCollection, parentId) {
			var name = '';
			switch (collection) {
				case 'comments':
					name = item.comment;
					break;
				case 'locations':
					name = item.locationName;
					break;
			}
			var title = 'Delete ' + name;
			var message = 'Are you sure you want to delete the item ' + name + '?';
			var actionDetails = {
				actionName: 'delete',
				collection: collection, // to add delete flag to doc from collection
				itemId: id,
				parentCollection: parentCollection, // to remove from the item id from the collection
				parentId: parentId,
			}

			this.MIX_showConfirmation(true, title, message, 290, "YES", "NO", actionDetails);
		},

		MIX_generateId() {
			return "xxxxyxxxyxxxxxxxxx".replace(/[xy]/g, function (c) {
				var r = (Math.random() * 16) | 0,
					v = c == "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			});
		},

		/**
		 * From Now
		 *
		 * Takes a date, format and ago parameters and returns a string containing the amount of time since creating the concern.
		 *
		 * @param date - the creation date
		 * @param formatIn - the format of the creation date
		 * @param ago - boolean to toggle the 'ago' keyword from the returned string
		 * @returns {string} - the time since concern creation e.g. '1 hour'
		 */
		MIX_fromNow: function (date, formatIn, ago) {
			return moment(date, formatIn).fromNow(ago);
		},

		// readyGooglePlaceApi() {
        //     const autocomplete = document.getElementById('autocomplete');
		// 	let array = [];
        //     gmaps().then((maps) => {
        //         const options = {
        //             // allow only UK addresses
        //             componentRestrictions: {country: "gb"},
        //             fields: ["address_components", "geometry"],
        //             types: ["address"],
        //         };
        //         // populates the places list
        //         this.autocomplete = new maps.places.Autocomplete(autocomplete, options)
        //         // triggered when the user selects an address from the list
        //         array = this.autocomplete.addListener('place_changed', this.updateAddressFields)
        //         })
		// 		return array
        //     },
       formatAddress(address, place){

            // const place = this.autocomplete.getPlace()
            // const address = place.address_components;

			let array= [];
            let streetNumber = "";
            let street = "";
            let region = "";
			let county ="";
            let city = "";
            let postcode = "";
			 // const location = place.geometry;
			 var lat = place.geometry.location.lat();
			 // get lng
			 var lng = place.geometry.location.lng();

            for(let i = 0; i < address.length; i++){
                if(address[i].types.includes('street_number')){
                    streetNumber = address[i].long_name
                }
                if(address[i].types.includes('route')){
                    street = address[i].long_name
                }
                if(address[i].types.includes('sublocality')) {
                    // adds neighbourhood to address
                    street = street + ', ' + address[i].long_name
                }
                if(address[i].types.includes('neighborhood')) {
                    // adds neighbourhood to address
                    region = address[i].long_name
                }
                if(address[i].types.includes('administrative_area_level_1')) {
                    // adds region to address
                    region = (region === '') ? address[i].short_name : (region + ', ' + address[i].short_name);
                }
                if(address[i].types.includes('administrative_area_level_2')) {
                    // adds county to address
                    county = address[i].long_name
                }
                if(address[i].types.includes('postal_town') || address[i].types.includes('locality') || address[i].types.includes('sublocality_level_1')){
                    city =  address[i].long_name
                }
                if(address[i].types.includes('postal_code') || address[i].types.includes('postal_code_suffix')){
                    postcode = address[i].long_name
                }
            }

			array.push(streetNumber);
			array.push(street);
			array.push(city);
			array.push(county);
			array.push(postcode);
			array.push(lat);
			array.push(lng)

            // populate the address fields
            // this.form.organisationAddressLine1 = streetNumber;
            // this.form.organisationAddressLine2 = street;
            // this.form.organisationAddressLine3 = '';
            // this.form.organisationAddressTown = city;
            // this.form.organisationAddressCounty = county;
            // this.form.organisationAddressPostcode = postcode.replace(/\s/g, '');;
			//  console.log(JSON.stringify(array,null,2))
			return array;
        },
	}
}

export default {
	install(Vue, options) {
		Vue.mixin(mixin)
	}
}
