<template>
    <div style="width: 100%">

        <!--Table-->
        <v-row v-if="$vuetify.breakpoint.width >= 600" class="ma-0" no-gutters>

            <v-data-table class="rounded-lg"
                          :headers="computedHeaders"
                          :items="filteredTableData"
                          :search="search"
                          style="width: 100%"
                          sort-by="SiteName">

                <!--Id-->
                <template v-slot:item.organisationName="{ item }">
                    <app-text category="text-small">{{ item.organisationName }}</app-text>
                </template>

                <!--Id-->
                <template v-slot:item.id="{ item }">
                    <app-text category="text-small">{{ item.id }}</app-text>
                </template>

                <!--Name-->
                <template v-slot:item.siteName="{ item }">
                    <app-text category="text-small">{{ item.siteName }}</app-text>
                </template>

                <!--Contact Name-->
                <template v-slot:item.siteContactName="{ item }">
                    <app-text category="text-small">{{ item.siteContactName }}</app-text>
                </template>

                <!--Telephone-->
                <template v-slot:item.siteContactTelephone="{ item }">
                    <app-text category="text-small">{{ item.siteContactTelephone }}</app-text>
                </template>

                <!--Email-->
                <template v-slot:item.siteContactEmail="{ item }">
                    <app-text category="text-small">{{ item.siteContactEmail }}</app-text>
                </template>

                <!--Action-->
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex align-center justify-end" style="height: 100%">
                        <v-btn @click.native="openSite(item.id)"
                            depressed class="white text--red">Open
                            <v-icon color="primary" class="icons8-forward"/>
                        </v-btn>
                    </div>
                </template>

            </v-data-table>

        </v-row>

        <!--Mobile Cards-->
        <v-row class="ma-0" no-gutters>
            <v-col v-if="$vuetify.breakpoint.width < 600">
                <div v-for="site in filteredTableData" :key="site.id">

                    <v-card @click.native="openSite(site.id)"
                            class="rounded-lg mb-4 pa-4"
                            flat>

                        <!--Site Name-->
                        <app-text category="text-default-bold" class="mb-2">
                            {{ site.siteName }}
                        </app-text>

                        <!--Other Details-->
                        <div class="d-flex">

                            <!--Site Id-->
                            <!-- <div class="d-flex align-center">
                                <v-icon class="icons8-skyscrapers mr-2" color="primary" size="16"/>
                                <app-text category="text-default" class="grey--text mr-4">
                                    {{ site.id }}
                                </app-text>
                            </div> -->

                            <!--Site Contact Name-->
                            <div class="d-flex align-center">
                                <v-icon class="icons8-person mr-2" color="primary" size="16"/>
                                <app-text category="text-default" class="grey--text">
                                    {{ site.siteContactName }}
                                </app-text>
                            </div>

                        </div>

                    </v-card>

                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'sitesTable',

    props: ['tableData', 'organisationId','search'],

    data: () => ({

        // search: '',

        headers: [
            {text: 'Organisation Name', value: 'organisationName', align: 'left', sortable: false, hidden: false},
            {text: 'Site Id', value: 'id', align: 'left', sortable: false, hidden: true},
            {text: 'Site Name', value: 'siteName', align: 'left', sortable: false},
            {text: 'Site Contact Name', value: 'siteContactName', align: 'left', sortable: false},
            {text: 'Site Contact Telephone', value: 'siteContactTelephone', align: 'left', sortable: false},
            {text: 'Site Contact Email', value: 'siteContactEmail', align: 'left', sortable: false},
            {text: '', value: 'actions', align: 'right', sortable: false, width: '134px'},
        ],
    }),

    computed: {

        /**
         * Computed Headers
         *
         * Returns a filtered headers array which is only those not marked as hidden.
         */
        computedHeaders() {
            const t = this

            return t.headers.filter(header => !header.hidden)
        },


        /**
         * Filtered Table Data
         *
         * Take the tableData and return an array of filtered Sites.
         *
         * @returns array - filtered users
         */
        filteredTableData() {
            const t = this
            let tableData = t.tableData

            if (t.organisationId) {
                tableData = tableData.filter(site =>  {
                    return (site.organisationId == t.organisationId || site.organisationId?.includes(t.organisationId))
                })
            } else {
                tableData = []
            }

            // Filter by Search
            // if (t.search) {
            //     tableData = tableData.filter(
            //         site => site.siteName.toUpperCase().match(t.search.toUpperCase()))
            // }

            return tableData
        },

    },

    methods: {
        /**
         * Open Site
         *
         * Filter through the sites and return the details of the site opened
         *
         * Emit 'switchComponent' to Sites component to render siteForm component
         */

        openSite(siteId) {
           const t = this
           let siteData

            if (siteId) {
                siteData = t.tableData.filter(site =>  {
                    return (site.id == siteId)
                })
            }

            t.$emit('switchComponent', 'sitesTable', siteData[0])

        }

    },

}

</script>

<style scoped>
    
</style>