<!--
Site Form

Renders the form to add/edit/delete a Site
-->
<template>
    <div>

        <!--Header-->
        <v-row  class="ma-0 pb-4" no-gutters>

            <!-- Back button -->
            <app-btn @click.native="switchToTable"
                     :class="formModeSite === 'New' ? 'mr-0' : 'mr-4'"
                     color="grey"
                     icon="icons8-back"/>

            <!--Title-->
            <app-text category="text-large">{{ form.siteName ? form.siteName : 'New Site' }}</app-text>

            <v-spacer/>

            <!--Action buttons-->
            <edit-icon :is-active="!formReadOnly" @click.native="editDocument"/>

        </v-row>

        <!--Page Content-->
        <v-row class="ma-0" no-gutters>

            <!--Form-->
            <v-form>

                <v-row no-gutters>

                    <!--Site Name -->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                                   :error="errors.siteName"
                                   :error-messages="errors.siteNameErrorMessage"
                                   label="Site Name"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-business-building"
                                   v-model.trim="form.siteName"/>
                    </v-col>

                    <!--Organisation Name -->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                                   disabled="disabled"
                                   label="Organisation Name"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-business-building"
                                   v-model.trim="form.organisationName"/>
                    </v-col>

                    <!--Site Contact-->
                    <v-col class="mt-4 mb-2 ml-2" cols="12">
                        <app-text category="text-medium" class="grey--text">Contact</app-text>
                    </v-col>

                    <!--Site Contact Name-->
                    <v-col cols="12">
                        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="pa-0">
                            <app-input input-type="textInput"
                                       :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                       :error="errors.siteContactName"
                                       :error-messages="errors.siteContactNameErrorMessage"
                                       label="Contact Name"
                                       :is-form-read-only="formReadOnly"
                                       prepend-inner-icon="icons8-person"
                                       v-model.trim="form.siteContactName"/>
                        </v-col>
                    </v-col>

                    <!--Site Contact Telephone-->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'mt-4 pr-2' : 'mt-4'"
                                   :error="errors.siteContactTelephone"
                                   :error-messages="errors.siteContactTelephoneErrorMessage"
                                   label="Telephone"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-phone"
                                   type="tel"
                                   v-model.trim="form.siteContactTelephone"/>
                    </v-col>

                    <!--Site Contact Email-->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'mt-4 pl-2' : 'mt-4'"
                                   :error="errors.siteContactEmail"
                                   :error-messages="errors.siteContactEmailErrorMessage"
                                   label="Email"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-email"
                                   type="email"
                                   v-model.trim="form.siteContactEmail"/>
                    </v-col>

                    <!--Site Address-->
                    <v-col class="mt-4 mb-2 ml-2" cols="12">
                        <app-text category="text-medium" class="grey--text">Site Address</app-text>
                    </v-col>

                    <!--Site Address Line 1-->
                    <v-col :cols="$vuetify.breakpoint.width   < 600 ? 12 : 6">
                        <v-text-field  ref="autocompleteSites" 
                                        id="autocompleteSites"  
                                        label="Address Line 1 / Search For Your Address" 
                                        filled
                                        flat
                                        :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                        class="rounded-lg"
                                        outlined
                                        background-color="white" 
                                        :disabled="formReadOnly"
                                        v-model.trim="form.siteAddressLine1"
                                        :error="errors.siteAddressLine1"
                                        :error-messages="errors.siteAddressLine1ErrorMessage"
                                        hide-details="auto"/>
                    </v-col>

                    <!--Site Address Line 2-->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                                   label="Address Line 2"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-company"
                                   v-model.trim="form.siteAddressLine2"/>
                    </v-col>

                    <!--Site Address Line 3-->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   class="mt-4"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                   label="Address Line 3"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-company"
                                   v-model.trim="form.siteAddressLine3"/>
                    </v-col>

                    <!--Site Town-->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   class="mt-4"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                                   label="Town"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-company"
                                   v-model.trim="form.siteAddressTown"/>
                    </v-col>

                    <!--Site County-->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   class="mt-4"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                   label="County"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-company"
                                   v-model.trim="form.siteAddressCounty"/>
                    </v-col>

                    <!--Site Postcode-->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   class="mt-4"
                                   :error="errors.siteAddressPostcode"
                                   :error-messages="errors.siteAddressPostcodeErrorMessage"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                                   label="Postcode"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-company"
                                   v-model.trim="form.siteAddressPostcode"/>
                    </v-col>

                    <!--Site Latitude and Longitude-->
                    <v-col class="mt-4 mb-2 ml-2" cols="12">
                        <app-text category="text-medium" class="grey--text">Mapping Coordinates</app-text>
                    </v-col>

                    <!--Site Latitude-->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                   :error="errors.siteLatitude"
                                   :error-messages="errors.siteLatitudeErrorMessage"
                                   label="Site Latitude"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-company"
                                   v-model.trim="form.siteLatitude"/>
                    </v-col>

                    <!--Site Longitude-->
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                        <app-input input-type="textInput"
                                   :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                                   :error="errors.siteLongitude"
                                   :error-messages="errors.siteLongitudeErrorMessage"
                                   label="Site Longitude"
                                   :is-form-read-only="formReadOnly"
                                   prepend-inner-icon="icons8-company"
                                   v-model.trim="form.siteLongitude"/>
                    </v-col>

                    <!--Anonymous Location-->
                    <!-- <v-col :cols="12">
                        <v-checkbox class="d-flex align-start mt-4"
                                    color="primary"
                                    hide-details
                                    :disabled="formReadOnly"
                                    label="By selecting this option, your Organisation will be visible on the ThinkAir map"
                                    v-model="form.hideOnThinkAirMap">
                        </v-checkbox>
                        <v-checkbox v-if="form.hideOnThinkAirMap" class="d-flex align-start"
                                    color="primary"
                                    hide-details
                                    :disabled="formReadOnly"
                                    label="By selecting this option, your Organisation’s marker will be visible, but kept anonymous"
                                    v-model="form.anonymityOnThinkAirMap">
                        </v-checkbox>
                    </v-col> -->


                </v-row>

                <!--Save button-->
                <div class="d-flex justify-end mt-4">

                    <app-btn v-if="!formReadOnly"
                             @click.native="validateForm"
                             color="success"
                             icon="icons8-save"
                             label="Update"/>

                </div>

            </v-form>

        </v-row>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import { gmaps } from 'x5-gmaps';

export default {

    name: "myOrganisationSitesForm",

    props: ['formMode', 'siteData', 'organisationId', 'organisationName', 'doesFormStartReadOnly'],

    components: {},

    data: () => ({
        collection: 'sites',
        collectionTitle: 'Sites',
        collectionItem: 'site',
        collectionItemTitle: 'Site',
        sitesCollectionData: [],
        organisationsCollectionData: [],
        errors: {
            siteName: false,
            siteNameErrorMessage: '',
            // organisationName:false,
            // organisationNameErrorMessage:'',
            siteContactName: false,
            siteContactNameErrorMessage: '',
            siteContactTelephone: false,
            siteContactTelephoneErrorMessage: '',
            siteContactEmail: false,
            siteContactEmailErrorMessage: '',
            siteAddressLine1: false,
            siteAddressLine1ErrorMessage: '',
            siteAddressPostcode: false,
            siteAddressPostcodeErrorMessage: '',
            siteLongitudeErrorMessage: '',
            siteLatitudeErrorMessage: '',

        },
        form: {
            id: '',
            organisationId: '',
            organisationName: '',
            siteName: '',
            siteContactName: '',
            siteContactTelephone: '',
            siteContactEmail: '',
            siteAddressLine1: '',
            siteAddressLine2: '',
            siteAddressLine3: '',
            siteAddressTown: '',
            siteAddressCounty: '',
            siteAddressPostcode: '',
            siteLongitude: '',
            siteLatitude: '',
            hideOnThinkAirMap: false,
            anonymityOnThinkAirMap: false,
        },
        // formMode:"",
        formModeSite: "",
        formReadOnly: false,
        tabs: 'site',
    }),

    computed: {
        computedIsFormReadOnly() {
            const t = this

            return t.$props.doesFormStartReadOnly
                ? t.$props.doesFormStartReadOnly
                : t.isFormReadOnly
        },
        ...mapGetters({
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult',
            GET_currentUser: 'GET_currentUser',
        })

    },
    
    methods: {
        readyGoogleApi() {
            console.log("inside")
            const autocomplete = document.getElementById('autocompleteSites');
            gmaps().then((maps) => {
                const options = {
                    // allow only UK addresses
                    componentRestrictions: {country: "gb"},
                    fields: ["address_components", "geometry"],
                    types: ["address"],
                };
                // populates the places list
                this.autocomplete = new maps.places.Autocomplete(autocomplete, options)
                // triggered when the user selects an address from the list
                this.autocomplete.addListener('place_changed', this.updateAddress)
                })
        },
        updateAddress(){
            const place = this.autocomplete.getPlace()
            const address = place.address_components;
            let array = this.formatAddress(address, place)
            // populate the address fields
            this.form.siteAddressLine1 = array[0];
            this.form.siteAddressLine2 = array[1];
            this.form.siteAddressTown = array[2];
            this.form.siteAddressCounty = array[3];
            this.form.siteAddressPostcode = array[4].replace(/\s/g, '');;
            this.form.siteLatitude = array[5];
            this.form.siteLongitude =array[6];
        },
        switchToTable(event) {
            const t = this

            t.$emit('switchComponent', 'myOrganisationSitesForm')
        },

        /**
         * Validate
         *
         * Validates the required fields.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */

        validateForm() {
            const t = this
            const emailRegex = /.+@.+\..+/
            const postcodeRegex = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]?[0-9][a-zA-Z]{2}$/

            t.errors.siteName = false
            t.errors.siteNameErrorMessage = ''
            // t.errors.organisationName = false
            // t.errors.organisationNameErrorMessage = ''
            t.errors.siteContactName = false
            t.errors.siteContactNameErrorMessage = ''
            t.errors.siteContactTelephone = false
            t.errors.siteContactTelephoneErrorMessage = ''
            t.errors.siteContactEmail = false
            t.errors.siteContactEmailErrorMessage = ''
            t.errors.siteAddressLine1 = false
            t.errors.siteAddressLine1ErrorMessage = ''
            t.errors.siteAddressPostcode = false
            t.errors.siteAddressPostcodeErrorMessage = ''

            // Site Name
            if (!t.form.siteName.trim()) {
                t.errors.siteName = true
                t.errors.siteNameErrorMessage = 'Site Name required'
            }

            // Organisation Name
            // if (!t.form.organisationName) {
            //     t.errors.organisationName = true
            //     t.errors.organisationNameErrorMessage = 'Organisation Name required'
            // }

            // Site Contact Name
            if (!t.form.siteContactName.trim()) {
                t.errors.siteContactName = true
                t.errors.siteContactNameErrorMessage = 'Contact Name required'
            }

            // Site Contact Telephone
            if (!t.form.siteContactTelephone.trim()) {
                t.errors.siteContactTelephone = true
                t.errors.siteContactTelephoneErrorMessage = 'Telephone Number required'
            }
            // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.siteContactTelephone.trim()[1]) && (t.form.siteContactTelephone.trim().length < 10 || t.form.siteContactTelephone.trim().length > 11)) {
                t.errors.siteContactTelephone = true
                t.errors.siteContactTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.siteContactTelephone.trim()[1]) && t.form.siteContactTelephone.trim().length !== 11) {
                t.errors.siteContactTelephone = true
                t.errors.siteContactTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Number must start 01, 02, 03 or 07
            else if (!['0'].includes(t.form.siteContactTelephone[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.form.siteContactTelephone[1])) {
                t.errors.siteContactTelephone = true
                t.errors.siteContactTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // Site Contact Email
            if (!t.form.siteContactEmail.trim()) {
                t.errors.siteContactEmail = true
                t.errors.siteContactEmailErrorMessage = 'Email required'
            }
            // Must be a (simple) valid email address
            else if (!emailRegex.test(t.form.siteContactEmail)) {
                t.errors.siteContactEmail = true
                t.errors.siteContactEmailErrorMessage = 'Email not valid'
            }

            // Site Address Line 1
            if (!t.form.siteAddressLine1.trim()) {
                t.errors.siteAddressLine1 = true
                t.errors.siteAddressLine1ErrorMessage = 'Site Address Line 1 required'
            }

            // Site Address Postcode
            if (t.form.siteAddressPostcode.trim() && !postcodeRegex.test(t.form.siteAddressPostcode.trim())) {
                t.errors.siteAddressPostcode = true
                t.errors.siteAddressPostcodeErrorMessage = 'Postcode not valid'
            }

            // // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                this.saveDocument()
            }
        },

        /**
         * Save Document
         *
         * Save or update the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this
            let createDocumentResult

            // If the formMode is Edit, update the current document
            // if (!t.computedIsFormReadOnly) {
            if (t.form?.id) {
                createDocumentResult = await t.MIX_updateDocument(t.collection, t.form)
            }

            // If the document saved/updated successfully, set the form back to viewable state
            if (createDocumentResult.code === 1) {
                t.formModeSite = 'View'
                t.formReadOnly = true
                // Switch back to table view
                t.switchToTable()
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)

        },

        /**
         * Edit Document
         *
         * Toggle the form between an editable, and a viewable state.
         */
        editDocument() {
            const t = this

            if (t.formReadOnly) {
                t.formModeSite = 'Edit'
                t.formReadOnly = false
            } else {
                t.formModeSite = 'View'
                t.formReadOnly = true
            }

        },

        /**
         * Delete Document
         *
         * Call for the confirmation box to delete a document.
         * The deletion is handled in this file's watch -> GET_confirmBoxResult handler function.
         */
        deleteDocument: function (id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: t.collection,
                    id: id,
                    title: `Delete ${t.collectionItemTitle}`,
                    description: `Are you sure you want to delete this ${t.collectionItemTitle}: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'Name']}</strong></div>`,
                    width,
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} updated`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving ${t.collectionItemTitle}`, null, null)
            }
        }

    },

    watch: {
        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === 'sites') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const deleteDocumentResult = await t.MIX_deleteDocument(t.GET_confirmBoxResult.data.collection, t.GET_confirmBoxResult.data.id);

                        if (deleteDocumentResult.code === 1) {
                            t.MIX_alert(1, `Site Deleted`, deleteDocumentResult.data, null);
                            // Switch back to table view
                            t.switchToTable()
                        } else {
                            t.MIX_alert(-1, `Error Deleting Site`, null, null);
                        }
                    }
                }

            }, deep: true
        }
    },

    async mounted() {
        const t = this

        // Get and populate form data
        if (t.formMode !== 'New') {
            t.form = t.siteData;
            t.formModeSite = t.formMode;
            // t.formModeSite = t.formMode;
            t.formReadOnly = true

        } else {
            t.formModeSite = t.formMode;
            t.form.organisationId = t.organisationId
            t.form.organisationName = t.organisationName
        }
       
        t.readyGoogleApi();
        

    },
    created(){

    }

}
</script>
