import Vue from 'vue';
import VueRouter from 'vue-router';

// store
import store from "@/store.js";

// main routes
import AppSupport from "@/views/appSupport/appSupport.vue";
import Organisations from "@/views/organisations/organisations.vue";
import Home from '@/views/home/home.vue';
import MapView from '@/views/map/map.vue';
import MapPage from "@/views/mapPage/mapPage.vue";
import MyOrganisation from "@/views/organisations/myOrganisation/myOrganisation.vue";

// import Sites from "@/views/sites/sites.vue";
import StaffDirectory from "@/views/staff/staff-directory.vue";
import Suspended from "@/views/suspended/suspended.vue"
import Unauthorised from "@/views/unauthorised/unauthorised.vue";
import UserProfile from "@/views/userProfile/userProfile.vue";

// * Settings
import Settings from "@/views/settings/settings.vue";
import Lookups from "@/views/settings/lookups.vue";

// auth routes
import Register from "@/views/auth/register.vue";
import Login from "@/views/auth/login.vue";
import ResetPassword from "@/views/auth/reset-password.vue";
import Restricted from "@/views/auth/restricted.vue";

// test methods
import Charts from "@/views/test/charts.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true,
			userLevel: ['CU', 'CA', 'SA'],
			userStatus: 'APPROVED'
		}
	},

	// Main Routes - Main Routes - Main Routes - Main Routes //
	{
		path: '/appSupport',
		name: 'AppSupport',
		component: AppSupport,
		meta: {
			requiresAuth: true,
			userLevel: ['CU', 'CA', 'SA'],
			userStatus: 'APPROVED'
		}
	},
	// {
	// 	path: '/map',
	// 	name: 'map',
	// 	component: MapView,
	// 	meta: {
	// 		requiresAuth: true,
	// 		userLevel: ['CU', 'CA', 'SA'],
	// 		userStatus: 'APPROVED'
	// 	}
	// },
	// {
	// 	path: '/mapPage',
	// 	name: 'MapPage',
	// 	component: MapPage,
	// 	meta: {
	// 		requiresAuth: true,
	// 		userLevel: ['CU', 'CA', 'SA'],
	// 		userStatus: 'APPROVED'
	// 	}
	// },
	{
		path: '/organisations',
		name: 'Organisations',
		component: Organisations,
		meta: {
			requiresAuth: true,
			userLevel: ['CA', 'SA'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/myOrganisation',
		name: 'MyOrganisation',
		component: MyOrganisation,
		meta: {
			requiresAuth: true,
			userLevel: ['CA', 'SA', 'CU'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/staff-directory',
		name: 'StaffDirectory',
		component: StaffDirectory,
		meta: {
			requiresAuth: true,
			userLevel: ['SA'],
			userStatus: 'APPROVED'
		}
	},
	{
		path: '/unauthorised',
		name: 'Unauthorised',
		component: Unauthorised,
		meta: {
			requiresAuth: true,
			userLevel: ['CU', 'CA', 'SA'],
			userStatus: 'PENDING'
		}
	},
	{
		path: '/suspended',
		name: 'Suspended',
		component: Suspended,
		meta: {
			requiresAuth: true,
			userLevel: ['CU', 'CA', 'SA'],
			userStatus: ['APPROVED', 'ARCHIVED', 'REJECTED','SUSPENDED',],
			deleted: true
		}
	},
	{
		path: '/userProfile',
		name: 'UserProfile',
		component: UserProfile,
		meta: {
			requiresAuth: true,
			userLevel: ['CU', 'CA', 'SA'],
			userStatus: 'APPROVED'
		}
	},

	// Settings - Settings - Settings - Settings //
	{
		path: '/settings',
		component: Settings,
		meta: {
			requiresAuth: true,
			userLevel: ['DA']
		}
	},
	{
		path: '/settings/lookups',
		name: 'Lookups',
		component: Lookups,
		meta: {
			requiresAuth: true,
			userLevel: ['DA']
		}
	},
	{ 	
	path: "/charts/:id?", 
	name: "Charts", 
	component: Charts,	
		meta: {
		requiresAuth: false,
		userLevel: ['CU', 'CA', 'SA'],
		userStatus: 'APPROVED'
		}
	},

	// *  AUTH ROUTES
	// ? register for normal user
	{path: "/register/:id?", name: "Register", component: Register},
	{path: "/login", name: "Login", component: Login},
	{path: "/restricted", name: "Restricted", component: Restricted},
	{path: "/reset-password", name: "ResetPassword", component: ResetPassword,},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

	if (requiresAuth) {
		let checkUser = store.getters.GET_currentUser;

		navigator.serviceWorker.register('/service-worker.js').then(reg => {
			// sometime later…
			reg.update();
		});
	
		// If the user is not logged in, redirect them to the login page
		if (!checkUser) {
			next("/login");
		}

		// // If the user has been deleted, redirect them to the Suspended page
		else if (checkUser.delete && !to.meta.deleted) {
			next({name: 'Suspended'})
		}

		// If the user's status is ARCHIVED/REJECTED/SUSPENDED/, redirect them to the Suspended page
		else if ((checkUser.userStatus === 'ARCHIVED' || checkUser.userStatus === 'REJECTED' || checkUser.userStatus === 'SUSPENDED') && !to.meta.userStatus.includes(checkUser.userStatus)) {
			next({name: 'Suspended'})
		}

		// If the user does not have access to the route, redirect them to the Home page
		else if (!to.meta.userLevel.includes(checkUser.userLevel)) {
			next({name: 'Home'})
		}

		// If the user's status is not APPROVED, redirect them to the Unauthorised page
		else if (!to.meta.userStatus.includes(checkUser.userStatus.toUpperCase()) && !to.meta.deleted) {
			next({name: 'Unauthorised'})
		}

		else {
			next();
		}

	} else next();
});

export default router;
