<template>

    <v-row no-gutters>

        <!--Page Loader-->
        <pageLoader :is-page-loading="isPageLoading"/>

        <!--Page Content-->
        <v-row v-if="!computedIsPageLoading" no-gutters>

            <!--Left panel-->
            <v-col v-if="computedSplitScreenColumns.left"
                   :cols="computedSplitScreenColumns.left"
                   class="pa-4">
            </v-col>

            <!--Right panel-->
            <v-col v-if="isRightPanelOpen"
                   :cols="computedSplitScreenColumns.right"
                   :class="computedSplitScreenColumns.left && 'borderLeft'">

                    <!--Device Name | Site Name-->
                    <div class="d-flex flex-column pa-4" style="width: 100%">

                        <div class="d-flex  justify-space-between">

                            <!--Page title-->
                            <page-title icon="icons8-business-building" page-title="My Organisation"/>

                            <!--Edit Icon-->
                            <edit-icon v-if="tabs === 'organisation'"
                                       @click.native="editForm"
                                       :isActive="!isFormReadOnly"/>

                        </div>

                        <v-select background-color="white"
                                  class="rounded-lg"
                                  filled
                                  flat
                                  hide-details
                                  :items="computedOrganisations"
                                  item-text="organisationName"
                                  outlined
                                  placeholder="Select Organisation"
                                  return-object
                                  :style="$vuetify.breakpoint.width < 800 ? 'width: 100%' : 'width: 50%'"
                                  v-model="selectedOrg"
                                  :menu-props="{ top: false, offsetY: true }">
                            <template v-slot:selection="{item}">
                                <app-text category="text-medium" class="grey--text">
                                    {{ item.organisationName }}
                                </app-text>
                            </template>
                        </v-select>

                    </div>

                <!--Tabs (Overview)-->
                <v-tabs show-arrows v-model="tabs" v-if="isEmpty()">

                    <v-tabs-slider color="accent"/>
                    <v-tab href="#organisation">
                        <app-text category="text-small">Organisation</app-text>
                    </v-tab>
                    <v-tab href="#members">
                        <app-text category="text-small">Members</app-text>
                    </v-tab>
                    <v-tab href="#sites">
                        <app-text category="text-small">Sites</app-text>
                    </v-tab>
                    <v-tab href="#devices">
                        <app-text category="text-small">Devices</app-text>
                    </v-tab>

                </v-tabs>

                <!--Tabs Content-->
                <v-tabs-items v-model="tabs">
                    <!--Organisation Tab Content-->
                    <v-tab-item value="organisation" class="appbackground">

                        <my-organisation-form :formData="selectedOrg"
                                              :doesFormStartReadOnly="isFormReadOnly"/>

                    </v-tab-item>

                    <!--Members Tab Content-->
                    <v-tab-item value="members" class="appbackground pa-1 ">
                        <my-organisation-members :organisationId="selectedOrg.id"
                                                 :organisationName="selectedOrg.organisationName"/>
                    </v-tab-item>

                    <!--Sites Tab Content-->
                    <v-tab-item value="sites" class="appbackground pa-1">
                        <my-organisation-sites :organisationId="selectedOrg.id"
                                               :organisationName="selectedOrg.organisationName"
                                               :doesFormStartReadOnly="isFormReadOnly"/>
                    </v-tab-item>

                    <!-- Devices Tab Content-->
                    <v-tab-item value="devices" class="appbackground pa-1 ">
                        <my-organisation-devices :organisationId="selectedOrg.id"
                                                 :organisationName="selectedOrg.organisationName"
                                                 :doesFormStartReadOnly="isFormReadOnly"/>
                    </v-tab-item>

                </v-tabs-items>

            </v-col>

        </v-row>

    </v-row>

</template>

<script>
import myOrganisationForm from '../myOrganisation/myOrganisationForm/myOrganisationForm.vue'
import myOrganisationMembers from '../myOrganisation/myOrganisationMembers/myOrganisationMembers.vue'
import myOrganisationSites from '../myOrganisation/myOrganisationSites/myOrganisationSites.vue'
import myOrganisationDevices from '../myOrganisation/myOrganisationDevices/myOrganisationDevices.vue'


export default {

    name: "myOrganisation",

    components: {myOrganisationForm, myOrganisationMembers, myOrganisationSites, myOrganisationDevices},

    data: () => ({
        organisationCollectionData: [],
        isOrganisationCollectionDataLoaded: false,
        isPageLoading: true,
        isRightPanelOpen: true,
        tabs: '',
        isFormReadOnly: true,
        tableData: [],
        selectedOrg: {},
    }),

    computed: {

        computedOrganisations() {
            const t = this;
            let filterOrg = t.organisationCollectionData;
            filterOrg = filterOrg.filter(org => t.GET_currentUser.organisationId.includes(org.id))
            if (filterOrg.length === 1) {
                t.selectedOrg = filterOrg[0]
            }
            return filterOrg;
        },

        /**
         * Computed Split Screen Columns
         *
         * Calculate the width of the left snd right panels, based on the currently selected functionality.
         * The left panel has the table, the right panel has the forms.
         *
         * @returns {{left: number, right: number}}
         */
        computedSplitScreenColumns() {
            const t = this
            let left = 12
            let right = 0

            if (t.$vuetify.breakpoint.width >= 0) {
                if (t.isRightPanelOpen) {
                    left = 0
                    right = 12
                }
            } else {
                if (t.isRightPanelOpen) {
                    left = 0
                    right = 12
                }
            }

            return {left, right}
        },

        computedIsPageLoading() {
            const t = this

            t.isPageLoading = !(
                t.isOrganisationCollectionDataLoaded)

            return t.isPageLoading

        },

    },

    methods: {

        isEmpty() {
            return Object.keys(this.selectedOrg).length !== 0
        },

        emittedCloseRightPanel() {
            const t = this
            // v-on:emitCloseRightPanel="emittedCloseRightPanel"

            // t.closeRightPanel()
        },

        closeRightPanel() {
            const t = this

            t.isFormReadOnly = true
            t.isRightPanelOpen = false
        },

        editForm() {
            const t = this

            t.isFormReadOnly = !t.isFormReadOnly
        },

        async getOrganisationsCollectionData() {
            const t = this;
            let organisationCollectionData = [];

            await t.$firebase.db.collection('organisations')
                .onSnapshot(snapShot => {
                    // Clear the table data to avoid duplications
                    organisationCollectionData = [];

                    snapShot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add documents that aren't marked as deleted
                        if (!document.hasOwnProperty('delete')) {
                            organisationCollectionData.push(document)
                        }
                    })
                    t.organisationCollectionData = organisationCollectionData;
                    t.isOrganisationCollectionDataLoaded = true;
                })
        },

        async getSitesCollectionData() {
            const t = this;
            let tableData = [];

            await t.$firebase.db.collection('sites')
                .onSnapshot(snapShot => {
                    // Clear the table data to avoid duplications
                    tableData = [];

                    snapShot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add documents that aren't marked as deleted
                        if (!document.hasOwnProperty('delete')) {
                            tableData.push(document)
                        }
                    })
                    t.tableData = tableData;
                })
        },

    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getSitesCollectionData();
        await t.getOrganisationsCollectionData();


    }
}
</script>

<style scoped>
.borderLeft {
    border-left: 4px solid #999999;
    min-height: calc(100vh - 128px);
    padding: 16px;
}
</style>
